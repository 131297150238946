<div class="card-container__card">
  <header>
    <p>Guias na fila</p>
    <mat-icon>schedule</mat-icon>
  </header>
  <strong>{{ summary.queuedGuias }}</strong>
</div>
<div class="card-container__card">
  <header>
    <p>Guias emitidas</p>
    <mat-icon>published_with_changes</mat-icon>
  </header>
  <strong>{{ summary.generatedGuias }}</strong>
</div>
<div class="card-container__card">
  <header>
    <p>Tempo de automação</p>
    <mat-icon>hourglass_empty</mat-icon>
  </header>
  <strong>{{ summary.automationElapsedTime }}</strong>
</div>
