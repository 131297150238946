<ng-container [ngTemplateOutlet]="_customer ? existingCustomer : newCustomer"></ng-container>

<ng-template #existingCustomer>
  <div class="dialog">
    <div class="dialog-title">
      <h1>[{{ _customer.id }}] {{ _customer.razaoSocial }}</h1>
    </div>
    <span class="dialog__hint">Nota: Os campos marcados com * são obrigatórios</span>
    <form [formGroup]="_form" (ngSubmit)="_handleSubmit()">
      <div class="dialog-body" mat-dialog-content>
        <div class="grid-row _col-2">
          <div class="form__input-wrapper">
            <vux-input
              type="text"
              name="cnpj"
              id="cnpj"
              label="CNPJ"
              inputMask="00.000.000/0000-00"
              icon="wysiwyg"
              formControlName="cnpj"
              [maskConfig]="_cnpjMaskConfig"
              [value]="_customer.cnpj"
              [required]="true"
              [hasErrors]="_fieldHasErrors(_cnpj)"
              (inputBlur)="_cnpj.markAsTouched()"></vux-input>
            <span
              *ngIf="_cnpj.errors?.['required'] && (_cnpj.touched || _cnpj.dirty)"
              class="validation-message _error">
              O CNPJ é obrigatório!
            </span>
            <span
              *ngIf="_cnpj.errors?.['minlength'] && (_cnpj.touched || _cnpj.dirty)"
              class="validation-message _error">
              O CNPJ deve conter exatos 14 dígitos!
            </span>
          </div>
          <div class="form__input-wrapper">
            <vux-input
              type="text"
              name="razao-social"
              id="razao-social"
              label="Razão Social"
              icon="account_box"
              placeholder="EMPRESA DEMONSTRAÇÃO LTDA"
              formControlName="razaoSocial"
              [value]="_customer.razaoSocial"
              [required]="true"
              [hasErrors]="_fieldHasErrors(_razaoSocial)"
              (inputBlur)="_razaoSocial.markAsTouched()"></vux-input>
            <span
              *ngIf="_razaoSocial.errors?.['required'] && (_razaoSocial.touched || _razaoSocial.dirty)"
              class="validation-message _error">
              A Razão Social é obrigatória!
            </span>
          </div>
        </div>
        <div class="grid-row _col-1">
          <div class="form__input-wrapper">
            <vux-input
              type="text"
              name="client-id"
              id="client-id"
              label="ID do Cliente"
              placeholder="empresa-demonstracao"
              icon="vpn_lock"
              formControlName="clientIdWrapper"
              inputMask="L*"
              [required]="false"
              [config]="_clientIdInputConfig"
              [maskConfig]="_clientIdMaskConfig"
              [value]="_customer.clientId"></vux-input>
            <span
              *ngIf="_clientId.errors?.['required'] && (_clientId.touched || _clientId.dirty)"
              class="validation-message _error">
              O ID do Cliente é obrigatório!
            </span>
            <span
              *ngIf="_clientId.errors?.['isValidated'] && (_clientId.touched || _clientId.dirty)"
              class="validation-message _info">
              O ID do Cliente deve ser validado!
            </span>
          </div>
        </div>
        <div class="grid-row _col-1">
          <div class="form__input-wrapper">
            <vux-input
              type="text"
              name="nome-fantasia"
              id="nome-fantasia"
              label="Nome Fantasia"
              placeholder="Empresa Demonstração"
              icon="face_retouching_natural"
              formControlName="nomeFantasia"
              [value]="_customer.nomeFantasia"
              [required]="true"
              [hasErrors]="_fieldHasErrors(_nomeFantasia)"
              (inputBlur)="_nomeFantasia.markAsTouched()"></vux-input>
            <span
              *ngIf="_nomeFantasia.errors?.['required'] && (_nomeFantasia.touched || _nomeFantasia.dirty)"
              class="validation-message _error">
              O Nome Fantasia é obrigatório!
            </span>
          </div>
        </div>
        <div class="grid-row _col-2">
          <div class="form__input-wrapper">
            <vux-datepicker
              name="inicio-contrato"
              id="inicio-contrato"
              label="Início do Contrato"
              formControlName="inicioContrato"
              mask="00/00/0000"
              [value]="_customer.inicioContrato"
              [required]="true"
              [hasErrors]="_fieldHasErrors(_inicioContrato)"
              (inputBlur)="_inicioContrato.markAsTouched()"></vux-datepicker>
            <span
              *ngIf="_inicioContrato.errors?.['required'] && (_inicioContrato.touched || _inicioContrato.dirty)"
              class="validation-message _error">
              A data de início do contrato é obrigatória!
            </span>
          </div>
          <div class="form__input-wrapper">
            <vux-datepicker
              name="fim-contrato"
              id="fim-contrato"
              label="Fim do Contrato"
              formControlName="fimContrato"
              mask="00/00/0000"
              [value]="_customer.fimContrato"
              [required]="true"
              [hasErrors]="_fieldHasErrors(_fimContrato)"
              (inputBlur)="_fimContrato.markAsTouched()"></vux-datepicker>
            <span
              *ngIf="_fimContrato.errors?.['required'] && (_fimContrato.touched || _fimContrato.dirty)"
              class="validation-message _error">
              A data de fim do contrato é obrigatória!
            </span>
          </div>
        </div>
        <div class="grid-row _col-2">
          <div class="form__input-wrapper">
            <vux-datepicker
              name="data-criacao"
              id="data-criacao"
              label="Data de Criação"
              mask="00/00/0000 00:00:00"
              [value]="_customer.dataCriacao"
              [disabled]="true"></vux-datepicker>
          </div>
          <div class="form__input-wrapper">
            <vux-datepicker
              name="data-atualizacao"
              id="data-atualizacao"
              label="Data de Atualização"
              mask="00/00/0000 00:00:00"
              [value]="_customer.dataAtualizacao"
              [disabled]="true"></vux-datepicker>
          </div>
        </div>
      </div>
      <div class="dialog-footer">
        <vux-button
          text="Atualizar Cliente"
          icon="mode_edit"
          type="submit"
          buttonType="success"
          [disabled]="_form.invalid"
          [showLoader]="_isLoading$ | async"></vux-button>
        <button mat-button matDialogClose>Fechar</button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #newCustomer>
  <div class="dialog">
    <div class="dialog-title">
      <h1>Novo Cliente</h1>
    </div>
    <span class="dialog__hint">Nota: Os campos marcados com * são obrigatórios</span>
    <form [formGroup]="_form" (ngSubmit)="_handleSubmit()">
      <div class="dialog-body" mat-dialog-content>
        <div class="grid-row _col-2">
          <div class="form__input-wrapper">
            <vux-input
              type="text"
              name="cnpj"
              id="cnpj"
              label="CNPJ"
              icon="wysiwyg"
              formControlName="cnpj"
              inputMask="00.000.000/0000-00"
              [maskConfig]="_cnpjMaskConfig"
              [required]="true"
              [hasErrors]="_fieldHasErrors(_cnpj)"
              (inputBlur)="_cnpj.markAsTouched()"></vux-input>
            <span
              *ngIf="_cnpj.errors?.['required'] && (_cnpj.touched || _cnpj.dirty)"
              class="validation-message _error">
              O CNPJ é obrigatório!
            </span>
            <span
              *ngIf="_cnpj.errors?.['minlength'] && (_cnpj.touched || _cnpj.dirty)"
              class="validation-message _error">
              O CNPJ deve conter exatos 14 dígitos!
            </span>
          </div>
          <div class="form__input-wrapper">
            <vux-input
              type="text"
              name="razao-social"
              id="razao-social"
              label="Razão Social"
              icon="account_box"
              placeholder="EMPRESA DEMONSTRAÇÃO LTDA"
              formControlName="razaoSocial"
              [required]="true"
              [hasErrors]="_fieldHasErrors(_razaoSocial)"
              (inputBlur)="_razaoSocial.markAsTouched()"></vux-input>
            <span
              *ngIf="_razaoSocial.errors?.['required'] && (_razaoSocial.touched || _razaoSocial.dirty)"
              class="validation-message _error">
              A Razão Social é obrigatória!
            </span>
          </div>
        </div>
        <div class="grid-row _col-1">
          <div class="form__input-wrapper">
            <vux-input
              type="text"
              name="client-id"
              id="client-id"
              label="ID do Cliente"
              placeholder="empresa-demonstracao"
              icon="vpn_lock"
              formControlName="clientIdWrapper"
              inputMask="L*"
              [required]="true"
              [config]="_clientIdInputConfig"
              [maskConfig]="_clientIdMaskConfig"
              [hasErrors]="_fieldHasErrors(_clientId)"
              (inputBlur)="_clientId.markAsTouched()"></vux-input>
            <span
              *ngIf="_clientId.errors?.['required'] && (_clientId.touched || _clientId.dirty)"
              class="validation-message _error">
              O ID do Cliente é obrigatório!
            </span>
            <span
              *ngIf="_clientId.errors?.['isValidated'] && (_clientId.touched || _clientId.dirty)"
              class="validation-message _info">
              O ID do Cliente deve ser validado!
            </span>
          </div>
        </div>
        <div class="grid-row _col-1">
          <div class="form__input-wrapper">
            <vux-input
              type="text"
              name="nome-fantasia"
              id="nome-fantasia"
              label="Nome Fantasia"
              placeholder="Empresa Demonstração"
              icon="face_retouching_natural"
              formControlName="nomeFantasia"
              [required]="true"
              [hasErrors]="_fieldHasErrors(_nomeFantasia)"
              (inputBlur)="_nomeFantasia.markAsTouched()"></vux-input>
            <span
              *ngIf="_nomeFantasia.errors?.['required'] && (_nomeFantasia.touched || _nomeFantasia.dirty)"
              class="validation-message _error">
              O Nome Fantasia é obrigatório!
            </span>
          </div>
        </div>
        <div class="grid-row _col-2">
          <div class="form__input-wrapper">
            <vux-datepicker
              name="inicio-contrato"
              id="inicio-contrato"
              label="Início do Contrato"
              formControlName="inicioContrato"
              mask="00/00/0000"
              [required]="true"
              [hasErrors]="_fieldHasErrors(_inicioContrato)"
              (inputBlur)="_inicioContrato.markAsTouched()"></vux-datepicker>
            <span
              *ngIf="_inicioContrato.errors?.['required'] && (_inicioContrato.touched || _inicioContrato.dirty)"
              class="validation-message _error">
              A data de início do contrato é obrigatória!
            </span>
          </div>
          <div class="form__input-wrapper">
            <vux-datepicker
              name="fim-contrato"
              id="fim-contrato"
              label="Fim do Contrato"
              formControlName="fimContrato"
              mask="00/00/0000"
              [required]="true"
              [hasErrors]="_fieldHasErrors(_fimContrato)"
              (inputBlur)="_fimContrato.markAsTouched()"></vux-datepicker>
            <span
              *ngIf="_fimContrato.errors?.['required'] && (_fimContrato.touched || _fimContrato.dirty)"
              class="validation-message _error">
              A data de fim do contrato é obrigatória!
            </span>
          </div>
        </div>
      </div>
      <div class="dialog-footer">
        <vux-button
          text="Adicionar Cliente"
          icon="add"
          type="submit"
          buttonType="default"
          [disabled]="_form.invalid"
          [showLoader]="_isLoading$ | async"></vux-button>
        <button mat-button matDialogClose>Fechar</button>
      </div>
    </form>
  </div>
</ng-template>
