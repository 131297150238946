<div *ngIf="_showMobileMenu" class="mobile-menu" #mobileMenu>
  <div class="mobile-menu__content">
    <button class="mobile-menu__hamburguer-button" type="button" (click)="_toggleMobileMenu()">
      <app-svg-icon [name]="'bars'"></app-svg-icon>
    </button>
    <img src="public/images/logo.png" alt="Vetor IT" />
  </div>
</div>

<div
  class="side-menu"
  [ngClass]="{
    '_collapsed--desktop': _isCollapsed && !_showMobileMenu,
    '_expanded--desktop': !_isCollapsed && !_showMobileMenu,
    '_collapsed--mobile': _isCollapsed && _showMobileMenu,
    '_expanded--mobile': !_isCollapsed && _showMobileMenu,
    _hidden: _hideSideMenu
  }"
  #sideMenu>
  <div class="side-menu__content" [ngClass]="{ _mobile: _showMobileMenu }">
    <div class="side-menu__logo-container" [ngClass]="{ _expanded: !_isCollapsed }">
      <img src="public/images/logo-icon.svg" alt="Vetor IT" />
      <ng-container *ngIf="!_isCollapsed && _showExpandedTexts" [ngTemplateOutlet]="expandedLogoTitle"></ng-container>
    </div>
    <nav>
      <ul>
        <div class="side-menu__tab-indicator" [ngStyle]="{ top: _getActiveTabPosition() }"></div>

        <li *ngFor="let _item of _items">
          <ng-container
            [ngTemplateOutlet]="_isCollapsed ? collapsedMenuItem : expandedMenuItem"
            [ngTemplateOutletContext]="{ _item }">
          </ng-container>
        </li>
      </ul>
    </nav>
    <div class="side-menu__settings-container" [ngSwitch]="_isCollapsed">
      <ng-container [ngTemplateOutlet]="collapsedSettingsContainer" *ngSwitchCase="true"></ng-container>
      <ng-container [ngTemplateOutlet]="expandedSettingsContainer" *ngSwitchCase="false"></ng-container>
    </div>
  </div>
</div>

<main>
  <router-outlet></router-outlet>
</main>

<ng-template #expandedLogoTitle>
  <span class="side-menu__logo-text _expanded-item" [ngClass]="{ _visible: _triggerTextAnimation }">
    Emissão de Guias
  </span>
</ng-template>

<ng-template let-item="_item" #collapsedMenuItem>
  <a
    [ngClass]="{ _active: item.menuItemValue === _activeItem.menuItemValue }"
    matTooltipPosition="right"
    [matTooltip]="item.menuItemName"
    (click)="_handleItemClick(item)">
    <app-svg-icon [name]="item.iconPath"></app-svg-icon>
  </a>
</ng-template>

<ng-template let-item="_item" #expandedMenuItem>
  <a
    class="side-menu__menu-item--expanded"
    [ngClass]="{ _active: item.menuItemValue === _activeItem.menuItemValue }"
    (click)="_handleItemClick(item)">
    <app-svg-icon [name]="item.iconPath"></app-svg-icon>
    <span
      *ngIf="_showExpandedTexts"
      class="side-menu__menu-item-name _expanded-item"
      [ngClass]="{ _visible: _triggerTextAnimation }"
      >{{ item.menuItemName }}
    </span>
  </a>
</ng-template>

<ng-template #collapsedSettingsContainer>
  <ng-container [ngTemplateOutlet]="collapsedUserInfo"></ng-container>
  <ng-container [ngTemplateOutlet]="collapsedRetractionButton"></ng-container>
</ng-template>

<ng-template #expandedSettingsContainer>
  <ng-container [ngTemplateOutlet]="expandedUserInfo"></ng-container>
  <ng-container [ngTemplateOutlet]="expandedRetractionButton"></ng-container>
</ng-template>

<ng-template #collapsedUserInfo>
  <button
    type="button"
    class="side-menu__exit-button _collapsed"
    matTooltipPosition="right"
    matTooltip="Encerrar Sessão"
    (click)="_signOut()">
    <mat-icon>logout</mat-icon>
  </button>
</ng-template>

<ng-template #expandedUserInfo>
  <div class="side-menu__user-info-container _expanded-item" [ngClass]="{ _visible: _triggerTextAnimation }">
    <div class="side-menu__user-info">
      <span class="side-menu__user-name">{{ _userPersonalName }}</span>
      <span class="side-menu__user-login">{{ _userName }}</span>
    </div>
    <button type="button" class="side-menu__exit-button _expanded" (click)="_signOut()">
      <mat-icon>logout</mat-icon>
    </button>
  </div>
</ng-template>

<ng-template #collapsedRetractionButton>
  <button type="button" class="side-menu__retraction-button" (click)="_toggleCollapseMenu()">
    <mat-icon>keyboard_double_arrow_right</mat-icon>
  </button>
</ng-template>

<ng-template #expandedRetractionButton>
  <button type="button" class="side-menu__retraction-button _expanded" (click)="_toggleCollapseMenu()">
    <mat-icon>keyboard_double_arrow_left</mat-icon>
    <span *ngIf="_showExpandedTexts" class="_expanded-item" [ngClass]="{ _visible: _triggerTextAnimation }">
      Recolher Menu
    </span>
  </button>
</ng-template>
