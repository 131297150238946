import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor (private _authService: AuthService, private _router: Router) {}

  public canActivate = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
    if (!this._authService.isAuthenticated()) {
      this._router.navigateByUrl('login');

      return false;
    }

    return true;
  }
}