import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DataPageModel } from 'src/app/modules/shared/data-table/models/data-table.model';
import { CustomerModel } from '../../models/customer.model';
import { CustomerState } from '../reducers/customer.reducer';

export const customerStateKey = 'customer';

export const selectCustomersListLoaderState = createSelector(
  createFeatureSelector(customerStateKey),
  (state: CustomerState): boolean => state.showListLoader
);

export const selectCustomerSubmitLoaderState = createSelector(
  createFeatureSelector(customerStateKey),
  (state: CustomerState): boolean => state.showSubmitLoader
);

export const selectCustomersContent = createSelector(
  createFeatureSelector(customerStateKey),
  (state: CustomerState): DataPageModel<CustomerModel> => state.customersContent
);
