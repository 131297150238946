import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { GuiaModule } from 'src/app/modules/guia/guia.module';
import { SummaryModule } from 'src/app/modules/summary/summary.module';

import { DashboardComponent } from './dashboard.component';
import { DashboardService } from './dashboard.service';

@NgModule({
  declarations: [DashboardComponent],
  imports: [
    CommonModule,
    // App imports
    SummaryModule,
    GuiaModule,
  ],
  providers: [Title, DashboardService],
})
export class DashboardModule {}
