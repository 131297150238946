<div class="mat-elevation-z2">
  <mat-toolbar class="table-toolbar">
    <button
      *ngIf="toolbarConfig?.buttonDisplay?.add"
      mat-icon-button
      aria-label="Add button"
      matTooltip="Novo"
      [matTooltipPosition]="'above'"
      (click)="_handleAddClick()">
      <mat-icon>add</mat-icon>
    </button>
    <button
      *ngIf="toolbarConfig?.buttonDisplay?.refresh"
      mat-icon-button
      aria-label="Refresh button"
      matTooltip="Atualizar dados"
      [matTooltipPosition]="'above'"
      (click)="_handleRefreshClick()">
      <mat-icon>sync</mat-icon>
    </button>
    <span class="example-spacer"></span>
    <div class="search-wrapper">
      <input placeholder="Pesquisar" />
      <mat-icon>search</mat-icon>
    </div>
  </mat-toolbar>
  <div class="table-wrapper">
    <div class="loader-background" *ngIf="showLoader"></div>
    <mat-spinner class="loader" *ngIf="showLoader"></mat-spinner>

    <table mat-table [dataSource]="_dataSource">
      <ng-content></ng-content>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="_handleRowClick(row)"></tr>
    </table>
  </div>
  <mat-paginator
    [pageSize]="pageOptions.pageSize"
    [length]="pageOptions.totalItems"
    [pageSizeOptions]="[5, 10, 20, 50, 100]"
    (page)="_handlePaginationChange($event)"
    showFirstLastButtons>
  </mat-paginator>
</div>
