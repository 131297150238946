import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { RetractableComponent } from '../shared/retractable-component/retractable-component';
import { SummaryModel } from './models/summary.model';
import { SummaryService } from './services/summary.service';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent extends RetractableComponent implements OnInit, OnDestroy {
  @HostBinding('class.card-container') _wrapperClass = true;

  summary: SummaryModel = {
    queuedGuias: 0,
    generatedGuias: 0,
    automationElapsedTime: '00h 00m 00s',
  };

  constructor(store$: Store, private summaryService: SummaryService) {
    super(store$);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.summaryService.getSummary().subscribe(summary => {
      this.summary = summary;

      this.summary.automationElapsedTime = this.automationElapsedTimeToString();
    });
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  private automationElapsedTimeToString(): string {
    let automationElapsedTime = this.summary.automationElapsedTime as number;

    const amountHours = Math.floor(automationElapsedTime / 60 / 60);

    let automationElapsedTimeString = '';

    if (amountHours >= 1) {
      automationElapsedTimeString = `${amountHours >= 10 ? amountHours : '0' + amountHours}h `;

      automationElapsedTime -= amountHours * 3600;
    } else {
      automationElapsedTimeString = '00h ';
    }

    const amountMinutes = Math.floor(automationElapsedTime / 60);

    if (amountMinutes >= 1) {
      automationElapsedTimeString += `${amountMinutes >= 10 ? amountMinutes : '0' + amountMinutes}m `;

      automationElapsedTime -= amountMinutes * 60;
    } else {
      automationElapsedTimeString += '00m ';
    }

    if (automationElapsedTime >= 1) {
      automationElapsedTimeString += `${
        automationElapsedTime >= 10 ? automationElapsedTime : '0' + automationElapsedTime
      }s`;
    } else {
      automationElapsedTimeString += '00s';
    }

    return automationElapsedTimeString;
  }
}
