import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { catchError, EMPTY, Observable, switchMap, throwError } from 'rxjs';
import { AuthService } from '../service/auth.service';
import { signOutAction } from '../store/actions/auth.action';
import { infoSnackBar } from 'src/app/modules/shared/snack-bar/helpers/snack-bar.helper';
@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(private store$: Store, private authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.match(new RegExp('/oauth/token'))) {
      return next.handle(request);
    }

    return this.handleRequestWithAuthentication(request, next);
  }

  private handleRequestWithAuthentication(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const accessToken = this.authService.getToken()!;
    const refreshToken = this.authService.getRefreshToken()!;

    return next.handle(this.cloneRequestWithToken(request, accessToken)).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          return this.authService.refreshSession(refreshToken).pipe(
            switchMap(payload => next.handle(this.cloneRequestWithToken(request, payload.access_token))),
            catchError((error: HttpErrorResponse) => {
              if (error.status === 401) {
                return this.terminateSession();
              }

              return throwError(() => new Error(error.message));
            })
          );
        }

        return throwError(() => new Error(error.message));
      })
    );
  }

  private cloneRequestWithToken(request: HttpRequest<unknown>, token: string): HttpRequest<unknown> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private terminateSession(): Observable<any> {
    this.store$.dispatch(
      signOutAction({
        message: 'Faça login para continuar',
        confirmationText: 'OK',
        snackBarType: infoSnackBar,
      })
    );

    return EMPTY;
  }
}
