import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map } from 'rxjs';
import { setMenuWidthAction } from 'src/app/modules/menu/store/actions/menu.action';
import { ViewModeEnum } from '../../models/view-mode.enum';
import { setViewModeAction } from '../actions/view-mode.action';

@Injectable()
export class ViewModeEffects {
  constructor(private actions$: Actions) {}

  setViewModeEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(setViewModeAction),
      filter(action => action.mode === ViewModeEnum.Mobile),
      map(() => setMenuWidthAction({ width: 0 }))
    );
  });
}
