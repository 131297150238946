import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SvgIconService } from './svg-icon.service';

@Component({
  selector: 'app-svg-icon',
  template: `<span style="_svgStyle" [innerHTML]="_svgIcon"></span>`,
})
export class SvgIconComponent implements OnInit {
  @Input() name?: string;

  _svgIcon: SafeHtml = {};

  constructor(private _svgIconService: SvgIconService, private _sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this._svgIconService.loadSvgFromFile(this.name!).subscribe(svgData => {
      this._svgIcon = this._sanitizer.bypassSecurityTrustHtml(svgData);
    });
  }
}
