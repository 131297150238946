import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthService } from './auth/service/auth.service';
import { RequestInterceptor } from './auth/middleware/request.interceptor';
import { initialViewModeState, viewModeReducer } from './view/view-mode/store/reducers/view-mode.reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './auth/store/effects/auth.effect';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ViewModeEffects } from './view/view-mode/store/effects/view-mode.effect';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    MatSnackBarModule,
    StoreModule.forFeature('viewMode', viewModeReducer, {
      initialState: initialViewModeState,
    }),
    EffectsModule.forFeature([AuthEffects, ViewModeEffects]),
  ],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {}
