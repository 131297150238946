import { Component, HostBinding, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { RetractableScreen } from 'src/app/modules/shared/retractable-screen/retractable-screen';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent extends RetractableScreen implements OnInit, OnDestroy {
  constructor(store$: Store, title: Title) {
    super(store$, title);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    super.setPageTitle('Emissão de Guias | Dashboard');
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
