import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DataPageModel, DataPagingModel, PageableParams } from '../../shared/data-table/models/data-table.model';
import { CustomerClientIdValidationResponse, CustomerInputModel, CustomerModel } from '../models/customer.model';

const CUSTOMER_ENDPOINT = 'v1/clientes';

@Injectable()
export class CustomerService {
  constructor(private http: HttpClient) {}

  findAll({ pageNumber, pageSize }: PageableParams): Observable<DataPageModel<CustomerModel>> {
    return this.http.get<DataPageModel<CustomerModel>>(`${environment.apiUrl}/${CUSTOMER_ENDPOINT}`, {
      params: {
        page: pageNumber,
        size: pageSize,
      },
    });
  }

  validateClientId(clientId: string): Observable<CustomerClientIdValidationResponse> {
    return this.http.get<CustomerClientIdValidationResponse>(
      `${environment.apiUrl}/${CUSTOMER_ENDPOINT}/client-id-availability`,
      {
        params: {
          clientId,
        },
      }
    );
  }

  store(customerInput: CustomerInputModel): Observable<CustomerModel> {
    return this.http.post<CustomerModel>(`${environment.apiUrl}/${CUSTOMER_ENDPOINT}`, customerInput);
  }

  update(id: number, customerInput: CustomerInputModel): Observable<CustomerModel> {
    return this.http.put<CustomerModel>(`${environment.apiUrl}/${CUSTOMER_ENDPOINT}/${id}`, customerInput);
  }

  delete(id: number): Observable<null> {
    return this.http.delete<null>(`${environment.apiUrl}/${CUSTOMER_ENDPOINT}/${id}`);
  }
}
