import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconModule } from './svg-icon/svg-icon.module';

import { MatTableModule } from '@angular/material/table';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CustomMatPaginator } from './data-table/helpers/custom-paginator';
import { DataTableComponent } from './data-table/data-table.component';
import { DialogEffects } from './dialog-manager/store/effects/dialog.effect';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {
  dialogManagerReducer,
  initialDialogManagerState,
} from './dialog-manager/store/reducers/dialog-manager.reducer';

@NgModule({
  declarations: [DataTableComponent],
  imports: [
    CommonModule,
    SvgIconModule,
    MatTableModule,
    MatPaginatorModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    StoreModule.forFeature('dialog-manager', dialogManagerReducer, {
      initialState: initialDialogManagerState,
    }),
    EffectsModule.forFeature([DialogEffects]),
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginator,
    },
  ],
  exports: [DataTableComponent],
})
export class SharedModule {}
