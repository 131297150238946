import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SummaryComponent } from './summary.component';
import { SummaryService } from './services/summary.service';

import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [SummaryComponent],
  imports: [CommonModule, MatIconModule],
  exports: [SummaryComponent],
  providers: [SummaryService],
})
export class SummaryModule {}
