import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { Observable } from 'rxjs';
import { DataPageModel, DataPagingModel } from '../../shared/data-table/models/data-table.model';
import { GuiaModel } from '../models/guia.model';

const GUIAS_ENDPOINT = 'v1/guias';
@Injectable()
export class GuiaService {
  constructor(private http: HttpClient) {}

  findAll({ pageNumber, pageSize }: DataPagingModel): Observable<DataPageModel<GuiaModel>> {
    return this.http.get<DataPageModel<GuiaModel>>(`${environment.apiUrl}/${GUIAS_ENDPOINT}`, {
      params: {
        page: pageNumber,
        size: pageSize,
      },
    });
  }

  fetchPDF(id: number): Observable<any> {
    return this.http.get<Blob>(`${environment.apiUrl}/${GUIAS_ENDPOINT}/${id}/pdf/attachment`, {
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }
}
