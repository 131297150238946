export const errorSnackBar = {
  duration: 10000,
  panelClass: ['custom-snackbar', '_error'], // Styles for custom snackbar declared in src/app/styles.scss (global styles)
};

export const successSnackBar = {
  duration: 7000,
  panelClass: ['custom-snackbar', '_success'], // Styles for custom snackbar declared in src/app/styles.scss (global styles)
};

export const infoSnackBar = {
  duration: 10000,
  panelClass: ['custom-snackbar', '_info'], // Styles for custom snackbar declared in src/app/styles.scss (global styles)
};
