<label *ngIf="label !== ''" [for]="name">{{ label }} {{ required ? '* ' : '' }}</label>
<div class="wrapper">
  <mat-datepicker-toggle class="datepicker-toggle" matSuffix [for]="datepicker" [disabled]="disabled!">
  </mat-datepicker-toggle>
  <div class="input-wrapper">
    <input
      class="_calendar"
      [matDatepicker]="datepicker"
      (dateChange)="_handleDateChange($event)"
      [value]="_calendarDate" />
    <input
      type="text"
      placeholder="DD/MM/AAAA"
      [mask]="mask"
      [dropSpecialCharacters]="false"
      [id]="id"
      [name]="name"
      [ngClass]="{
        _invalid: hasErrors
      }"
      [ngModel]="_date"
      [disabled]="disabled!"
      (ngModelChange)="_handleDateManualChange($event)"
      (blur)="_handleInputBlur()" />
  </div>
  <mat-datepicker #datepicker></mat-datepicker>
</div>
