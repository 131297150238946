import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MenuState } from '../reducers/menu.reducer';

export const menuStateKey = 'menu';

export const selectMenuRetraction = createSelector(
  createFeatureSelector(menuStateKey),
  (state: MenuState): boolean | undefined => state.isCollapsed
);

export const selectMenuWidth = createSelector(
  createFeatureSelector(menuStateKey),
  (state: MenuState): number | undefined => state.width
);
