import { ComponentType } from '@angular/cdk/portal';
import { MatDialogRef } from '@angular/material/dialog';
import { createAction, props } from '@ngrx/store';

export enum DialogManagerActions {
  OPEN_DIALOG = '[Dialog] Open',
  OPEN_DIALOG_SUCCESS = '[Dialog] Open SUCCESS',
}

export const openDialogAction = createAction(
  DialogManagerActions.OPEN_DIALOG,
  props<{ componentType: ComponentType<unknown>; data?: any }>()
);

export const openDialogSuccessAction = createAction(
  DialogManagerActions.OPEN_DIALOG_SUCCESS,
  props<{ dialogRef: MatDialogRef<any> }>()
);
