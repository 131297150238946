import { APP_BASE_HREF } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { viewModeReducer } from './core/view/view-mode/store/reducers/view-mode.reducer';
import { DialogsModule } from './modules/dialogs/dialogs.module';
import { MenuModule } from './modules/menu/menu.module';
import { menuReducer } from './modules/menu/store/reducers/menu.reducer';
import { SharedModule } from './modules/shared/shared.module';
import { CustomerManagementModule } from './pages/customer-management/customer-management.module';
import { DashboardModule } from './pages/dashboard/dashboard.module';
import { LoginModule } from './pages/login/login.module';

const reducers = [menuReducer, viewModeReducer];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RouterModule,

    //App modules
    CoreModule,
    LoginModule,
    MenuModule,
    DialogsModule,
    DashboardModule,
    CustomerManagementModule,
    SharedModule,
    EffectsModule.forRoot([]),
    StoreModule.forRoot(
      {},
      {
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
        },
      }
    ),
    StoreDevtoolsModule.instrument({ maxAge: 25 }),
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: '/ui',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
