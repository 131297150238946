<button
  mat-button
  class="vux-button"
  [type]="type"
  [ngClass]="{
    _default: buttonType === 'default',
    _success: buttonType === 'success',
    _danger: buttonType === 'danger'
  }"
  [disabled]="disabled">
  <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
</button>

<ng-template #buttonContent>
  <mat-icon *ngIf="icon !== ''" [ngClass]="{ '_loader-active': showLoader }">{{ icon }}</mat-icon>
  <span [ngClass]="{ '_loader-active': showLoader }"
    >{{ text }}<mat-spinner *ngIf="showLoader" diameter="23"></mat-spinner
  ></span>
</ng-template>
