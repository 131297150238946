import { MatDialogRef } from '@angular/material/dialog';
import { createReducer, on } from '@ngrx/store';
import { openDialogAction, openDialogSuccessAction } from '../actions/dialog.action';

export interface DialogManagerState {
  dialogRef: MatDialogRef<any>;
}

export const initialDialogManagerState: DialogManagerState = {
  dialogRef: {} as MatDialogRef<any>,
};

export const dialogManagerReducer = createReducer(
  initialDialogManagerState,
  on(openDialogSuccessAction, (state, action): DialogManagerState => {
    return { ...state, dialogRef: action.dialogRef };
  })
);
