<div class="header">
  <div class="inner-header flex">
    <section class="login-form">
      <img src="public/images/logo.png" alt="Logo" width="210" />
      <h1>Emissão de Guias</h1>
      <form [formGroup]="form" (ngSubmit)="handleSubmit()">
        <input
          type="name"
          name="username"
          id="username"
          placeholder="Usuário"
          autocomplete="off"
          spellcheck="false"
          formControlName="username" />
        <input type="password" name="password" id="password" placeholder="Senha" formControlName="password" />
        <button type="submit" [disabled]="form.invalid || (form.touched && showLoader)">
          <mat-spinner diameter="35" *ngIf="showLoader"></mat-spinner>
          Entrar
        </button>
      </form>
    </section>
  </div>
  <div>
    <svg
      class="waves"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 24 150 28"
      preserveAspectRatio="none"
      shape-rendering="auto">
      <defs>
        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
      </defs>
      <g class="parallax">
        <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
        <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
        <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
        <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />
      </g>
    </svg>
  </div>
  <div class="content flex">Vetor IT&copy; {{ currentYear }}. Todos os direitos reservados.</div>
</div>
