<div class="dialog">
  <div class="dialog-title">
    <h1>Encerrar Sessão</h1>
  </div>
  <div class="dialog-body" mat-dialog-content>
    <span>Deseja realmente encerrar sua sessão na plataforma?</span>
  </div>
  <div class="dialog-footer">
    <button mat-button matDialogClose class="dialog-footer__sign-out" (click)="_signOut()">Sair</button>
    <button mat-button matDialogClose>Cancelar</button>
  </div>
</div>
