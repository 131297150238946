<div class="wrapper _label">
  <label *ngIf="label !== ''" [for]="name">{{ label }} {{ required ? '* ' : '' }}</label>
  <mat-icon
    *ngIf="config?.helpInfo?.showHelp"
    class="help-icon"
    [matTooltip]="config?.helpInfo?.helpMessage!"
    matTooltipPosition="right"
    >help</mat-icon
  >
</div>
<div class="wrapper" [ngClass]="{ _validate: config?.validation?.validate }">
  <div class="wrapper">
    <mat-icon *ngIf="icon" class="input-icon">{{ icon }}</mat-icon>
    <input
      spellcheck="false"
      [type]="type"
      [name]="name"
      [id]="id"
      [mask]="inputMask"
      [patterns]="maskConfig?.maskPatterns"
      [specialCharacters]="maskConfig?.maskSpecialCharacters ?? []"
      [showMaskTyped]="maskConfig?.showMaskTyped ?? false"
      [dropSpecialCharacters]="maskConfig?.dropSpecialCharacters ?? true"
      [ngModel]="_value"
      [placeholder]="placeholder"
      [disabled]="disabled!"
      [ngClass]="{
        '_has-icon': icon,
        '_has-validation-icon': config?.validation?.validate,
        _invalid: (_isInputValidated && !_isInputValid) || hasErrors,
        _valid: _isInputValidated && _isInputValid
      }"
      (ngModelChange)="_handleInputChange($event)"
      (blur)="_handleInputBlur()" />
    <mat-icon
      *ngIf="config?.validation?.validate && _isInputValidated"
      class="validation-icon"
      [ngClass]="_isInputValid ? '_success' : '_error'">
      {{ _isInputValid ? 'done' : 'close' }}
    </mat-icon>
  </div>
  <button
    mat-button
    *ngIf="config?.validation?.validate"
    class="validate-button"
    type="button"
    (click)="_validateInput()">
    Validar
  </button>
</div>
<span *ngIf="_isInputValidated && !_isInputValid" class="validation-message _error">
  {{ config?.validation?.validationErrorMessage }}
</span>
<span
  *ngIf="_isInputValidated && _isInputValid && config?.validation?.showValidationSuccessMessage"
  class="validation-message _success">
  {{ config?.validation?.validationSuccessMessage }}
</span>
