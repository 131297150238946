import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class SvgIconService {
  constructor(private _http: HttpClient) {}

  loadSvgFromFile(fileName: string): Observable<string> {
    return this._http.get(`public/icons/${fileName}.svg`, { responseType: 'text' });
  }
}
