import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TokenResponseModel } from 'src/app/core/auth/models/token.model';

import { AuthService } from 'src/app/core/auth/service/auth.service';
import { LoginModel } from './models/login.model';
@Injectable()
export class LoginService {
  constructor(private _authService: AuthService) {}

  signIn = (loginProps: LoginModel): Observable<TokenResponseModel> => {
    return this._authService.signIn(loginProps);
  };
}
