import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { SummaryModel } from 'src/app/modules/summary/models/summary.model';

import { environment } from 'src/environments/environment';

@Injectable()
export class DashboardService {
  private readonly ESTATISTICAS_ENDPOINT = 'v1/estatisticas';

  constructor(private _http: HttpClient) {}

  getSummary(): Observable<SummaryModel> {
    return this._http
      .get<SummaryModel>(`${environment.apiUrl}/${this.ESTATISTICAS_ENDPOINT}/overview`, { observe: 'response' })
      .pipe(map(response => response.body as SummaryModel));
  }
}
