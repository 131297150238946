<div class="dialog">
  <div class="dialog-title">
    <h1>Remover Cliente</h1>
  </div>
  <div class="dialog-body" mat-dialog-content>
    <span
      >Deseja realmente remover o cliente <strong>{{ _customer.nomeFantasia }}</strong
      >?</span
    >
  </div>
  <div class="dialog-footer">
    <vux-button
      text="Sim, remover"
      icon="delete"
      type="button"
      buttonType="danger"
      [showLoader]="_isLoading$ | async"
      (click)="_deleteCustomer()"></vux-button>
    <button mat-button matDialogClose>Cancelar</button>
  </div>
</div>
