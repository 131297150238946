import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { SvgIconComponent } from './svg-icon.component';
import { SvgIconService } from './svg-icon.service';

@NgModule({
  declarations: [SvgIconComponent],
  imports: [CommonModule, HttpClientModule],
  exports: [SvgIconComponent],
  providers: [SvgIconService],
})
export class SvgIconModule {}
