import { createAction, props } from '@ngrx/store';
import { DataPageModel, PageableParams } from 'src/app/modules/shared/data-table/models/data-table.model';
import { CustomerInputModel, CustomerModel } from '../../models/customer.model';

export enum CustomerActions {
  CREATE_CUSTOMER = '[Customer] Create New',
  CREATE_CUSTOMER_SUCCESS = '[Customer] Create New SUCCESS',
  CREATE_CUSTOMER_FAILURE = '[Customer] Create New FAILURE',
  UPDATE_CUSTOMER = '[Customer] Update Existing',
  UPDATE_CUSTOMER_SUCCESS = '[Customer] Update Existing SUCCESS',
  UPDATE_CUSTOMER_FAILURE = '[Customer] Update Existing FAILURE',
  DELETE_CUSTOMER = '[Customer] Delete Existing',
  DELETE_CUSTOMER_SUCCESS = '[Customer] Delete Existing SUCCESS',
  DELETE_CUSTOMER_FAILURE = '[Customer] Delete Existing FAILURE',
  FETCH_CUSTOMERS = '[Customer] Fetch All',
  FETCH_CUSTOMERS_SUCCESS = '[Customer] Fetch All SUCCESS',
  FETCH_CUSTOMERS_FAILURE = '[Customer] Fetch All FAILURE',
}

export const createCustomerAction = createAction(
  CustomerActions.CREATE_CUSTOMER,
  props<{ customerInput: CustomerInputModel }>()
);

export const createCustomerSuccessAction = createAction(
  CustomerActions.CREATE_CUSTOMER_SUCCESS,
  props<{ customer: CustomerModel }>()
);

export const createCustomerFailureAction = createAction(CustomerActions.CREATE_CUSTOMER_FAILURE);

export const updateCustomerAction = createAction(
  CustomerActions.UPDATE_CUSTOMER,
  props<{ id: number; customerInput: CustomerInputModel }>()
);

export const updateCustomerSuccessAction = createAction(
  CustomerActions.UPDATE_CUSTOMER_SUCCESS,
  props<{ customer: CustomerModel }>()
);

export const updateCustomerFailureAction = createAction(CustomerActions.UPDATE_CUSTOMER_FAILURE);

export const deleteCustomerAction = createAction(CustomerActions.DELETE_CUSTOMER, props<{ customer: CustomerModel }>());

export const deleteCustomerSuccessAction = createAction(
  CustomerActions.DELETE_CUSTOMER_SUCCESS,
  props<{ customer: CustomerModel }>()
);

export const deleteCustomerFailureAction = createAction(CustomerActions.DELETE_CUSTOMER_FAILURE);

export const requestCustomersAction = createAction(
  CustomerActions.FETCH_CUSTOMERS,
  props<{ pageable: PageableParams }>()
);

export const requestCustomersSuccessAction = createAction(
  CustomerActions.FETCH_CUSTOMERS_SUCCESS,
  props<{ payload: DataPageModel<CustomerModel> }>()
);

export const requestCustomersFailureAction = createAction(CustomerActions.FETCH_CUSTOMERS_FAILURE);
