import {
  OnInit,
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { lastValueFrom } from 'rxjs';
import { DataPageModel, DataPagingModel, ToolbarConfig } from '../../shared/data-table/models/data-table.model';
import { openDialogAction } from '../../shared/dialog-manager/store/actions/dialog.action';
import { RetractableComponent } from '../../shared/retractable-component/retractable-component';
import { errorSnackBar } from '../../shared/snack-bar/helpers/snack-bar.helper';
import { GuiaModalComponent } from '../guia-modal/guia-modal.component';
import { GuiaModel } from '../models/guia.model';

import { GuiaService } from '../services/guia.service';

@Component({
  selector: 'app-guia-list',
  templateUrl: './guia-list.component.html',
  styleUrls: ['./guia-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GuiaListComponent extends RetractableComponent implements OnInit, OnDestroy {
  _displayedColumns: string[] = [
    'id',
    'codReceita',
    'dataVenct',
    'nomeGuia',
    'uf',
    'valor',
    'valorJuros',
    'valorMulta',
    'valorTotal',
    'status',
    'pdf',
  ];

  _showLoader = false;

  _toolbarConfig: ToolbarConfig = {
    buttonDisplay: {
      add: false,
      refresh: true,
    },
  };

  _pageOptions: DataPagingModel = {
    pageNumber: 0,
    pageSize: 5,
    totalItems: 0,
  };

  _pagedContent: DataPageModel<GuiaModel> = {} as DataPageModel<GuiaModel>;

  private timeoutRef: any;

  constructor(
    store$: Store,
    private guiaService: GuiaService,
    private snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef
  ) {
    super(store$);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this._fetchContent();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  _fetchContent(): void {
    this._showLoader = true;

    this.guiaService.findAll(this._pageOptions).subscribe({
      next: payload => {
        this._pagedContent = payload;

        this._pageOptions.totalItems = this._pagedContent.totalElements;

        this.formatProperties();

        this._showLoader = false;

        this.cdr.markForCheck();
      },
      error: () => {
        this.snackBar.open(
          'Um erro inesperado ocorreu ao realizar a busca de guias cadastradas. Por favor, tente novamente mais tarde.',
          'OK',
          errorSnackBar
        );

        this._showLoader = false;

        this.cdr.markForCheck();
      },
    });
  }

  _handleGuiaClick(guia: GuiaModel): void {
    this.timeoutRef = setTimeout(() => {
      this.store$.dispatch(openDialogAction({ componentType: GuiaModalComponent, data: guia }));
    }, 0.1);
  }

  _handlePaginationChange(event: PageEvent): void {
    if (event.pageSize !== this._pageOptions.pageSize) {
      this._pageOptions.pageNumber = 0;
      this._pageOptions.pageSize = event.pageSize;
    } else {
      this._pageOptions.pageNumber = event.pageIndex;
    }

    this._fetchContent();
  }

  public _fetchPDF = (guia: GuiaModel): void => {
    // Set timeout is used to avoid multiple events (_handleGuiaClick). If this function is executed, _handleGuiaClick shall not be
    setTimeout(async () => {
      clearTimeout(this.timeoutRef);

      const data = await lastValueFrom(this.guiaService.fetchPDF(guia.id));

      if (data.body) {
        this.openPDF(data.body, `${guia.id}_${guia.codReceita}_${guia.nomeGuia}_${guia.uf}.pdf`);
      }
    }, 0.1);
  };

  private openPDF(data: Blob, fileName: string): void {
    var file = new Blob([data], { type: 'application/pdf' });

    var a = document.createElement('a');
    var url = URL.createObjectURL(file);

    a.href = url;
    a.download = fileName;

    window.open(url);
  }

  private formatProperties(): void {
    this._pagedContent.content.map(guia => {
      guia.cnpj = guia.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');

      const valorTotal = guia.valor + guia.juros + guia.multa + guia.valorFecp + guia.acresc;

      guia.valorFormatado = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(guia.valor);
      guia.jurosFormatado = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(guia.juros);
      guia.multaFormatado = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(guia.multa);
      guia.fecpFormatado = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
        guia.valorFecp
      );
      guia.valorTotalFormatado = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
        valorTotal
      );
    });

    this._pagedContent.content.sort((a, b) => a.id - b.id).reverse();
  }
}
