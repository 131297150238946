import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, tap } from 'rxjs';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  private svgCache: Map<string, HttpResponse<any>> = new Map();

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes('.svg')) {
      const cachedResponse = this.svgCache.get(req.url);

      if (cachedResponse) {
        return of(cachedResponse.clone());
      }

      return next.handle(req).pipe(
        tap(stateEvent => {
          if (stateEvent instanceof HttpResponse) {
            this.svgCache.set(req.url, stateEvent);
          }
        })
      );
    }

    return next.handle(req);
  }
}
