import { Component, Input } from '@angular/core';

@Component({
  selector: 'vux-button',
  templateUrl: './vux-button.component.html',
  styleUrls: ['./vux-button.component.scss'],
})
export class VuxButtonComponent {
  @Input() text: string = '';
  @Input() type?: 'button' | 'submit' = 'button';
  @Input() buttonType?: 'default' | 'success' | 'info' | 'danger' = 'default';
  @Input() icon?: string = '';
  @Input() disabled?: boolean = false;
  @Input() showLoader?: boolean | null = false;
}
