import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatColumnDef, MatTable, MatTableDataSource } from '@angular/material/table';
import { RetractableComponent } from '../retractable-component/retractable-component';
import { DataPagingModel, ToolbarConfig } from './models/data-table.model';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTableComponent
  extends RetractableComponent
  implements OnInit, OnChanges, AfterContentChecked, OnDestroy
{
  @HostBinding('class.data-table') _wrapperClass = true;

  @ViewChild(MatTable, { static: true }) dataTable: MatTable<any> = {} as MatTable<any>;
  @ContentChildren(MatColumnDef) childrenColumnDefs: QueryList<MatColumnDef> = {} as QueryList<MatColumnDef>;

  @Input() pageOptions: DataPagingModel = {} as DataPagingModel;
  @Input() toolbarConfig: ToolbarConfig = {} as ToolbarConfig;
  @Input() displayedColumns: string[] = [];
  @Input() data: any[] = [];
  @Input() showLoader?: boolean | null = {} as boolean;

  @Output() rowClick = new EventEmitter<any>();
  @Output() addItem = new EventEmitter();
  @Output() refreshData = new EventEmitter();
  @Output() paginationChange = new EventEmitter<PageEvent>();

  _dataSource = new MatTableDataSource<any>(this.data);

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { data } = changes;

    if (data?.currentValue) {
      this._dataSource = new MatTableDataSource<any>(this.data);
    }
  }

  ngAfterContentChecked(): void {
    this.childrenColumnDefs.forEach(columnDef => this.dataTable.addColumnDef(columnDef));
  }

  _handleRowClick(row: any): void {
    this.rowClick.emit(row);
  }

  _handleAddClick(): void {
    this.addItem.emit();
  }

  _handleRefreshClick(): void {
    this.refreshData.emit();
  }

  _handlePaginationChange($event: PageEvent): void {
    this.paginationChange.emit($event);
  }
}
