import { Directive, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { MOBILE_BREAKPOINT } from 'src/app/core/view/view-mode/helpers/view.consts';
import { ViewModeEnum } from 'src/app/core/view/view-mode/models/view-mode.enum';
import { selectMenuWidth } from '../../menu/store/selectors/menu.selector';

@Directive()
export abstract class RetractableComponent implements OnInit, OnDestroy {
  @HostBinding('class') protected _hostClass: string = '';

  protected menuWidth: number = {} as number;

  private menuWidthSubscription: Subscription = {} as Subscription;

  @HostListener('window:resize', ['$event']) onResize() {
    let viewMode: ViewModeEnum;

    if (window.innerWidth <= MOBILE_BREAKPOINT) {
      viewMode = ViewModeEnum.Mobile;
    } else {
      viewMode = ViewModeEnum.Desktop;
    }

    const needToRetract = window.innerWidth - this.menuWidth < MOBILE_BREAKPOINT;

    needToRetract ? (this._hostClass = '_retracted') : (this._hostClass = '');
  }

  constructor(protected store$: Store) {}

  ngOnInit(): void {
    // eslint-disable-next-line @ngrx/no-store-subscription
    this.menuWidthSubscription = this.store$.pipe(select(selectMenuWidth)).subscribe(menuWidth => {
      if (typeof menuWidth !== 'undefined') {
        this.menuWidth = menuWidth;

        this.onResize();
      }
    });
  }

  ngOnDestroy(): void {
    this.menuWidthSubscription?.unsubscribe();
  }
}
