import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'vux-datepicker',
  templateUrl: './vux-datepicker.component.html',
  styleUrls: ['./vux-datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => VuxDatepickerComponent),
    },
  ],
})
export class VuxDatepickerComponent implements OnInit, ControlValueAccessor {
  @Input() name: string = '';
  @Input() id: string = '';
  @Input() label: string = '';
  @Input() required: boolean = false;
  @Input() hasErrors: boolean = false;
  @Input() value?: any;
  @Input() disabled?: boolean = false;
  @Input() mask?: string = '';

  @Output() inputBlur = new EventEmitter();

  _date: string | null = null;
  _calendarDate: Date | null = null;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.value) {
      const date: Date = new Date(this.value);

      if (this.isDateValid(date)) {
        this._date = this.dateToString(date);
        this._calendarDate = date;

        this.cdr.markForCheck();
      }
    }
  }

  onChange(event: any): void {}

  _handleDateChange(event: any): void {
    if (event.value) {
      this._date = this.dateToString(event.value);
      this.onChange(event.value);

      this.cdr.markForCheck();
    }
  }

  _handleDateManualChange(event: any): void {
    if (event === this._date) return;

    this._date = event;

    const date: Date = this.stringToDate(event);

    if (this.isDateValid(date)) {
      this.onChange(date);
      this._calendarDate = date;

      this.cdr.markForCheck();
    }
  }

  _handleInputBlur(): void {
    this.inputBlur.emit();
  }

  private isDateValid(date: Date, year?: string): boolean {
    if (year) {
      if (year.length < 4) return false;
    }

    if (isNaN(Date.parse(String(date)))) return false;

    return true;
  }

  private stringToDate(dateString: string): Date {
    const [day, month, year]: string[] = dateString.split('/');

    return new Date(Number(year), Number(month) - 1, Number(day));
  }

  private dateToString(date: Date): string {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: 'America/Sao_Paulo',
    };

    return new Intl.DateTimeFormat('pt-BR', options).format(date.setDate(date.getDate() + 1));
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  writeValue() {}
  registerOnTouched() {}
}
