import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { VuxInputConfig, VuxInputMaskConfig } from './models/vux-input.model';

@Component({
  selector: 'vux-input',
  templateUrl: './vux-input.component.html',
  styleUrls: ['./vux-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => VuxInputComponent),
    },
  ],
})
export class VuxInputComponent implements OnInit, ControlValueAccessor {
  @HostBinding('class.vux-input') _hostClass = true;

  @Input() type: 'text' | 'password' | 'number' = 'text';
  @Input() name: string = '';
  @Input() id: string = '';
  @Input() label: string = '';
  @Input() required: boolean = false;
  @Input() disabled?: boolean = false;
  @Input() value?: any;
  @Input() icon?: string = '';
  @Input() inputMask?: string = '';
  @Input() placeholder?: string = '';
  @Input() hasErrors?: boolean;
  @Input() config?: VuxInputConfig;
  @Input() maskConfig?: VuxInputMaskConfig;

  @Output() inputBlur = new EventEmitter();
  @Output() inputValidation = new EventEmitter();

  _isInputValid = false;
  _isInputValidated = false;

  _value: any = null;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.value) {
      this._value = this.value;

      if (this.config?.validation?.validate) {
        this._validateInput();
      }

      this.cdr.markForCheck();
    }
  }

  onChange(event: any): void {}

  async _validateInput(): Promise<void> {
    if (!this._value) {
      this.resetValidation();

      return;
    }

    if (this.value === this._value) {
      this._isInputValid = true;
    } else {
      this._isInputValid = await this.config?.validation?.validationFn!(this._value);
    }

    this._isInputValidated = true;

    this.onChange({ inputValue: this._value, isValid: this._isInputValid, isValidated: this._isInputValidated });

    this.cdr.markForCheck();
  }

  _handleInputChange(event: any): void {
    if (event === this._value) return;

    this.config?.validation?.validate && this.resetValidation();
    this._value = event;

    if (this.config?.validation?.validate) {
      this.onChange({ inputValue: this._value, isValid: this._isInputValid, isValidated: this._isInputValidated });
    } else {
      this.onChange(this._value);
    }
  }

  _handleInputBlur(): void {
    this.inputBlur.emit();
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  private resetValidation(): void {
    this._isInputValidated = false;
    this._isInputValid = false;
  }

  // AbstractControl needed methods
  writeValue(): void {}
  registerOnTouched(): void {}
}
