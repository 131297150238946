import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { RetractableScreen } from 'src/app/modules/shared/retractable-screen/retractable-screen';

@Component({
  selector: 'app-customer-management',
  templateUrl: './customer-management.component.html',
  styleUrls: ['./customer-management.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomerManagementComponent extends RetractableScreen implements OnInit, OnDestroy {
  constructor(store$: Store, title: Title) {
    super(store$, title);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    super.setPageTitle('Emissão de Guias | Clientes');
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
