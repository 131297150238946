import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SummaryModel } from '../models/summary.model';

@Injectable()
export class SummaryService {
  private readonly SUMMARY_ENDPOINT = `${environment.apiUrl}/v1/estatisticas/overview`;

  constructor(private _http: HttpClient) {}

  getSummary(): Observable<SummaryModel> {
    return this._http.get<SummaryModel>(this.SUMMARY_ENDPOINT);
  }
}
