import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';
import { AuthService } from '../../service/auth.service';
import { signOutAction } from '../actions/auth.action';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  signOutEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(signOutAction),
        tap(action => {
          this.authService.endSession();
          this.router.navigateByUrl('login');
          this.snackBar.open(action.message, action.confirmationText, action.snackBarType);
        })
      );
    },
    { dispatch: false }
  );
}
