import { createReducer, on } from '@ngrx/store';
import { setMenuRetractionModeAction, setMenuWidthAction } from '../actions/menu.action';

export interface MenuState {
  isCollapsed: boolean | undefined;
  width: number | undefined;
}

export const initialMenuState: MenuState = {
  isCollapsed: undefined,
  width: undefined,
};

export interface AppState {
  menu: MenuState;
}

export const menuReducer = createReducer(
  initialMenuState,
  on(setMenuRetractionModeAction, (state, action): MenuState => {
    return { ...state, isCollapsed: action.isCollapsed };
  }),
  on(setMenuWidthAction, (state, action): MenuState => {
    return { ...state, width: action.width };
  })
);
