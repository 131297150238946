import { createAction, props } from '@ngrx/store';

export enum AuthActions {
  SIGN_OUT = '[Auth] Sign Out',
}

export const signOutAction = createAction(
  AuthActions.SIGN_OUT,
  props<{ message: string; confirmationText: string; snackBarType: any }>()
);
