import { Component, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { signOutAction } from 'src/app/core/auth/store/actions/auth.action';
import { successSnackBar } from '../../shared/snack-bar/helpers/snack-bar.helper';

@Component({
  selector: 'app-sign-out-dialog',
  templateUrl: './sign-out-dialog.component.html',
  styleUrls: ['./sign-out-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SignOutDialogComponent {
  constructor(private store$: Store) {}

  _signOut(): void {
    this.store$.dispatch(
      signOutAction({
        message: 'Sua sessão foi encerrada corretamente e você foi redirecionado para o login.',
        confirmationText: 'OK',
        snackBarType: successSnackBar,
      })
    );
  }
}
