import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VuxButtonComponent } from './vux-button.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [VuxButtonComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule, MatProgressSpinnerModule],
  exports: [VuxButtonComponent],
})
export class VuxButtonModule {}
