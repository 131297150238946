export const MenuItemEnum = {
  Dashboard: {
    key: 'menu_item_dashboard',
    routerUrl: 'dashboard',
  },
  Customers: {
    key: 'menu_item_customers',
    routerUrl: 'clientes',
  },
  AccessControl: {
    key: 'menu_item_access_control',
    routerUrl: 'controle-acesso',
  },
};
