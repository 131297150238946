import { createReducer, on } from '@ngrx/store';
import { ViewModeEnum } from '../../models/view-mode.enum';
import { setViewModeAction } from '../actions/view-mode.action';

export interface ViewModeState {
  mode: ViewModeEnum | undefined;
}

export const initialViewModeState: ViewModeState = {
  mode: undefined,
};

export interface AppState {
  viewMode: ViewModeState;
}

export const viewModeReducer = createReducer(
  initialViewModeState,
  on(setViewModeAction, (state, action): ViewModeState => {
    return { ...state, mode: action.mode };
  })
);
