import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerListComponent } from './customer-list/customer-list.component';
import { HttpClientModule } from '@angular/common/http';
import { CustomerService } from './services/customer.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SharedModule } from '../shared/shared.module';
import { MatTableModule } from '@angular/material/table';
import { CustomerDialogComponent } from './customer-dialog/customer-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { VuxInputModule } from '../vux/vux-input/vux-input.module';
import { VuxDatepickerModule } from '../vux/vux-datepicker/vux-datepicker.module';
import { ReactiveFormsModule } from '@angular/forms';
import { VuxButtonModule } from '../vux/vux-button/vux-button.module';
import { StoreModule } from '@ngrx/store';
import { customerReducer, initialCustomerState } from './store/reducers/customer.reducer';
import { CustomerEffects } from './store/effects/customer.effect';
import { EffectsModule } from '@ngrx/effects';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DeleteCustomerDialogComponent } from './delete-customer-dialog/delete-customer-dialog.component';

@NgModule({
  declarations: [CustomerListComponent, CustomerDialogComponent, DeleteCustomerDialogComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    MatTableModule,
    MatSnackBarModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatIconModule,
    MatSnackBarModule,
    MatTooltipModule,
    ReactiveFormsModule,
    VuxInputModule,
    VuxDatepickerModule,
    VuxButtonModule,
    SharedModule,
    StoreModule.forFeature('customer', customerReducer, {
      initialState: initialCustomerState,
    }),
    EffectsModule.forFeature([CustomerEffects]),
  ],
  exports: [CustomerListComponent, CustomerDialogComponent],
  providers: [CustomerService],
})
export class CustomerModule {}
