<app-data-table
  [displayedColumns]="_displayedColumns"
  [pageOptions]="_pageOptions"
  [showLoader]="_isLoading$ | async"
  [data]="_pagedContent.content"
  [toolbarConfig]="_toolbarConfig"
  (refreshData)="_fetchContent()"
  (addItem)="_handleAddCustomerClick()"
  (rowClick)="_handleCustomerClick($event)"
  (paginationChange)="_handlePaginationChange($event)">
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>ID</th>
    <td mat-cell *matCellDef="let element">{{ element.id }}</td>
  </ng-container>

  <ng-container matColumnDef="clientId">
    <th class="table-cell _centralized" mat-header-cell *matHeaderCellDef>Identificador</th>
    <td class="table-cell _centralized" mat-cell *matCellDef="let element">{{ element.clientId }}</td>
  </ng-container>

  <ng-container matColumnDef="cnpj">
    <th class="table-cell _centralized" mat-header-cell *matHeaderCellDef>CNPJ</th>
    <td class="table-cell _centralized" mat-cell *matCellDef="let element">{{ element.cnpj }}</td>
  </ng-container>

  <ng-container matColumnDef="razaoSocial">
    <th class="table-cell _centralized" mat-header-cell *matHeaderCellDef>Razão Social</th>
    <td class="table-cell _centralized" mat-cell *matCellDef="let element">{{ element.razaoSocial }}</td>
  </ng-container>

  <ng-container matColumnDef="nomeFantasia">
    <th class="table-cell _centralized" mat-header-cell *matHeaderCellDef>Nome Fantasia</th>
    <td class="table-cell _centralized" mat-cell *matCellDef="let element">{{ element.nomeFantasia }}</td>
  </ng-container>

  <ng-container matColumnDef="inicioContrato">
    <th class="table-cell _centralized" mat-header-cell *matHeaderCellDef>Início do Contrato</th>
    <td class="table-cell _centralized" mat-cell *matCellDef="let element">{{ element.inicioContratoFormatado }}</td>
  </ng-container>

  <ng-container matColumnDef="fimContrato">
    <th class="table-cell _centralized" mat-header-cell *matHeaderCellDef>Fim do Contrato</th>
    <td class="table-cell _centralized" mat-cell *matCellDef="let element">{{ element.fimContratoFormatado }}</td>
  </ng-container>

  <ng-container matColumnDef="delete">
    <th class="table-cell _centralized" mat-header-cell *matHeaderCellDef></th>
    <td class="table-cell _centralized" mat-cell *matCellDef="let element">
      <button
        class="delete-button"
        matTooltip="Excluir Cliente"
        matTooltipPosition="right"
        (click)="_handleDeleteCustomerClick(element)">
        <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
      </button>
    </td>
  </ng-container>
</app-data-table>
