<app-data-table
  [displayedColumns]="_displayedColumns"
  [pageOptions]="_pageOptions"
  [showLoader]="_showLoader"
  [data]="_pagedContent.content"
  [toolbarConfig]="_toolbarConfig"
  (refreshData)="_fetchContent()"
  (rowClick)="_handleGuiaClick($event)"
  (paginationChange)="_handlePaginationChange($event)">
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>ID</th>
    <td mat-cell *matCellDef="let element">{{ element.id }}</td>
  </ng-container>

  <ng-container matColumnDef="codReceita">
    <th class="table-cell _centralized" mat-header-cell *matHeaderCellDef>Cód. Receita</th>
    <td class="table-cell _centralized" mat-cell *matCellDef="let element">{{ element.codReceita }}</td>
  </ng-container>

  <ng-container matColumnDef="dataVenct">
    <th class="table-cell _centralized" mat-header-cell *matHeaderCellDef>Data Vencto.</th>
    <td class="table-cell _centralized" mat-cell *matCellDef="let element">{{ element.dataVenct }}</td>
  </ng-container>

  <ng-container matColumnDef="nome">
    <th class="table-cell _centralized" mat-header-cell *matHeaderCellDef>Nome</th>
    <td class="table-cell _centralized" mat-cell *matCellDef="let element">{{ element.nome }}</td>
  </ng-container>

  <ng-container matColumnDef="nomeGuia">
    <th class="table-cell _centralized" mat-header-cell *matHeaderCellDef>Guia</th>
    <td class="table-cell _centralized" mat-cell *matCellDef="let element">{{ element.nomeGuia }}</td>
  </ng-container>

  <ng-container matColumnDef="cnpj">
    <th class="table-cell _centralized" mat-header-cell *matHeaderCellDef>CNPJ</th>
    <td class="table-cell _centralized" mat-cell *matCellDef="let element">{{ element.cnpj }}</td>
  </ng-container>

  <ng-container matColumnDef="uf">
    <th class="table-cell _centralized" mat-header-cell *matHeaderCellDef>UF</th>
    <td class="table-cell _centralized" mat-cell *matCellDef="let element">{{ element.uf }}</td>
  </ng-container>

  <ng-container matColumnDef="valor">
    <th class="table-cell _centralized" mat-header-cell *matHeaderCellDef>Valor</th>
    <td class="table-cell _centralized" mat-cell *matCellDef="let element">{{ element.valorFormatado }}</td>
  </ng-container>

  <ng-container matColumnDef="valorMulta">
    <th class="table-cell _centralized" mat-header-cell *matHeaderCellDef>Multa</th>
    <td class="table-cell _centralized" mat-cell *matCellDef="let element">{{ element.multaFormatado }}</td>
  </ng-container>

  <ng-container matColumnDef="valorJuros">
    <th class="table-cell _centralized" mat-header-cell *matHeaderCellDef>Juros</th>
    <td class="table-cell _centralized" mat-cell *matCellDef="let element">{{ element.jurosFormatado }}</td>
  </ng-container>

  <ng-container matColumnDef="valorTotal">
    <th class="table-cell _centralized" mat-header-cell *matHeaderCellDef>Valor Total</th>
    <td class="table-cell _centralized" mat-cell *matCellDef="let element">{{ element.valorTotalFormatado }}</td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th class="table-cell _centralized" mat-header-cell *matHeaderCellDef>Status</th>
    <td class="table-cell _centralized _status" mat-cell *matCellDef="let element">
      <span
        class="status-badge"
        [ngClass]="{
          'status-error': element.status === 'ERROR',
          'status-queued': element.status === 'ON_QUEUE',
          'status-success': element.status === 'COMPLETED',
          'status-processing': element.status === 'PROCESSING'
        }">
        {{ element.statusDesc }}
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="pdf">
    <th class="table-cell _centralized" mat-header-cell *matHeaderCellDef>PDF</th>
    <td class="table-cell _centralized" mat-cell *matCellDef="let element">
      <button
        matTooltip="Visualizar arquivo PDF"
        matTooltipPosition="right"
        class="pdf-download"
        [disabled]="element.status !== 'COMPLETED'"
        (click)="_fetchPDF(element)">
        <mat-icon>picture_as_pdf</mat-icon>
      </button>
    </td>
  </ng-container>
</app-data-table>
