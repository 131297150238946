import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable, tap } from 'rxjs';
import { LoginModel } from 'src/app/pages/login/models/login.model';
import { environment } from 'src/environments/environment';
import { TokenResponseModel } from '../models/token.model';

const AUTH_ENDPOINT = `${environment.apiUrl}/oauth/token`;

const HEADERS_CONFIG = {
  'Content-Type': 'application/x-www-form-urlencoded',
  Authorization: `Basic ${environment.basicToken}`,
};

@Injectable()
export class AuthService {
  private readonly ACCESS_TOKEN_KEY = '@wsguias:access_token';
  private readonly REFRESH_TOKEN_KEY = '@wsguias:refresh_token';

  constructor(private store$: Store, private _http: HttpClient) {}

  signIn = ({ username, password }: LoginModel): Observable<TokenResponseModel> => {
    const payload = new URLSearchParams({
      grant_type: 'password',
      username: username,
      password: password,
    });

    return this._http
      .post<TokenResponseModel>(AUTH_ENDPOINT, payload, { observe: 'response', headers: HEADERS_CONFIG })
      .pipe(
        tap(response => {
          const authPayload = response.body as TokenResponseModel;

          this.initializeSession(authPayload.access_token, authPayload.refresh_token);
        }),
        map(response => response.body as TokenResponseModel)
      );
  };

  refreshSession = (refreshToken: string): Observable<TokenResponseModel> => {
    const payload = new URLSearchParams({
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
    });

    return this._http
      .post<TokenResponseModel>(AUTH_ENDPOINT, payload, { observe: 'response', headers: HEADERS_CONFIG })
      .pipe(
        tap(response => {
          const authPayload = response.body as TokenResponseModel;

          this.initializeSession(authPayload.access_token, authPayload.refresh_token);
        }),
        map(response => response.body as TokenResponseModel)
      );
  };

  isAuthenticated(): boolean {
    return !!this.getToken();
  }

  getToken(): string | null {
    return localStorage.getItem(this.ACCESS_TOKEN_KEY);
  }

  getRefreshToken(): string | null {
    return localStorage.getItem(this.REFRESH_TOKEN_KEY);
  }

  initializeSession(accessToken: string, refreshToken: string) {
    this.storeToken(accessToken);
    this.storeRefreshToken(refreshToken);
  }

  storeToken(accessToken: string): void {
    localStorage.setItem(this.ACCESS_TOKEN_KEY, accessToken);
  }

  storeRefreshToken(refreshToken: string): void {
    localStorage.setItem(this.REFRESH_TOKEN_KEY, refreshToken);
  }

  endSession(): void {
    this.removeToken();
    this.removeRefreshToken();
  }

  removeToken(): void {
    localStorage.removeItem(this.ACCESS_TOKEN_KEY);
  }

  removeRefreshToken(): void {
    localStorage.removeItem(this.REFRESH_TOKEN_KEY);
  }
}
