import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { LoginService } from './login.service';

import { lastValueFrom } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { errorSnackBar } from 'src/app/modules/shared/snack-bar/helpers/snack-bar.helper';
import { LoginModel } from './models/login.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  public currentYear = new Date().getFullYear();

  public showLoader = false;

  public form = this.fb.group({
    username: this.fb.control('', [Validators.required]),
    password: this.fb.control('', [Validators.required]),
  });

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private loginService: LoginService,
    private snackBar: MatSnackBar
  ) {}

  async handleSubmit(): Promise<void> {
    this.showLoader = true;

    try {
      await lastValueFrom(this.loginService.signIn(this.form.value as LoginModel));

      this.snackBar.dismiss();

      this.router.navigateByUrl('dashboard');
    } catch (err) {
      const errorInstance = err as HttpErrorResponse;

      if (this.isCredentialError(errorInstance)) {
        this.snackBar.open('Usuário e/ou senha inválidos!', 'OK', errorSnackBar);
      } else {
        this.snackBar.open(
          'Um erro inesperado ocorreu ao realizar o login. Por favor, tente novamente mais tarde.',
          'OK',
          errorSnackBar
        );
      }
    }

    this.showLoader = false;
  }

  private isCredentialError(errorInstance: HttpErrorResponse) {
    if (errorInstance.status === 400 && errorInstance.error.error_description === 'Bad credentials') {
      return true;
    }

    return false;
  }
}
