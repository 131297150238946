import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CustomerModel } from '../models/customer.model';
import { CustomerService } from '../services/customer.service';
import { deleteCustomerAction } from '../store/actions/customer.action';
import { selectCustomerSubmitLoaderState } from '../store/selectors/customer.selector';

@Component({
  selector: 'app-delete-customer-dialog',
  templateUrl: './delete-customer-dialog.component.html',
  styleUrls: ['./delete-customer-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DeleteCustomerDialogComponent implements OnInit {
  _isLoading$: Observable<boolean> = {} as Observable<boolean>;

  constructor(
    private store$: Store,
    private customerService: CustomerService,
    @Inject(MAT_DIALOG_DATA) public _customer: CustomerModel
  ) {}

  ngOnInit(): void {
    this._isLoading$ = this.store$.pipe(select(selectCustomerSubmitLoaderState));
  }

  _deleteCustomer(): void {
    this.store$.dispatch(deleteCustomerAction({ customer: this._customer }));
  }
}
