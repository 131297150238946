import { createAction, props } from '@ngrx/store';

export enum MenuActions {
  SET_MENU_RETRACTION_MODE_ACTION = '[Menu] Set Retraction Mode',
  SET_MENU_WIDTH_ACTION = '[Menu] Set Width',
}

export const setMenuRetractionModeAction = createAction(
  MenuActions.SET_MENU_RETRACTION_MODE_ACTION,
  props<{ isCollapsed: boolean }>()
);

export const setMenuWidthAction = createAction(MenuActions.SET_MENU_WIDTH_ACTION, props<{ width: number }>());
