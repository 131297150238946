import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { RetractableComponent } from '../../shared/retractable-component/retractable-component';
import { GuiaModel } from '../models/guia.model';

@Component({
  selector: 'app-guia-modal',
  templateUrl: './guia-modal.component.html',
  styleUrls: ['./guia-modal.component.scss'],
})
export class GuiaModalComponent extends RetractableComponent implements OnInit {
  constructor(store$: Store, @Inject(MAT_DIALOG_DATA) public _guia: GuiaModel) {
    super(store$);
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }
}
