import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ViewModeEnum } from 'src/app/core/view/view-mode/models/view-mode.enum';
import { selectViewMode } from 'src/app/core/view/view-mode/store/selectors/view-mode.selector';
import { MenuItemEnum } from './models/menu-item.enum';
import { MenuItem } from './models/menu.model';
import { setMenuRetractionModeAction, setMenuWidthAction } from './store/actions/menu.action';
import { DecodedTokenModel } from 'src/app/core/auth/models/token.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from 'src/app/core/auth/service/auth.service';
import { Router } from '@angular/router';
import { openDialogAction } from '../shared/dialog-manager/store/actions/dialog.action';
import { SignOutDialogComponent } from '../dialogs/sign-out-dialog/sign-out-dialog.component';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MenuComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly MENU_STATE_VALUE = '@wsguias:retraction_menu_state_value';

  viewMode$: Observable<ViewModeEnum | undefined> = {} as Observable<ViewModeEnum | undefined>;

  private textAnimationDebouncer: any;
  private menuWidthDebouncer: any;

  @ViewChild('sideMenu') menuElementRef: ElementRef<HTMLDivElement> = {} as ElementRef;

  elementResizeObserver$: ResizeObserver;

  _menuItemEnum = MenuItemEnum;

  _items: MenuItem[] = [
    {
      iconPath: 'chart',
      menuItemName: 'Dashboard',
      menuItemValue: MenuItemEnum.Dashboard,
      isFirstItem: true,
    },
    {
      iconPath: 'users',
      menuItemName: 'Clientes',
      menuItemValue: MenuItemEnum.Customers,
      neededAuthorities: ['ADMIN'],
    },
    {
      iconPath: 'fingerprint',
      menuItemName: 'Controle de Acesso',
      menuItemValue: MenuItemEnum.AccessControl,
      neededAuthorities: ['ADMIN'],
    },
  ];

  _activeItem: MenuItem = this._items[0];

  // Menu control attrs

  _isCollapsed = false;
  _showExpandedTexts = false;
  _triggerTextAnimation = false;
  _showMobileMenu = false;
  _isMobileBrowser = false;
  _hideSideMenu = false;

  // Menu dynamic props

  _userPersonalName = '';
  _userName = '';

  constructor(
    private store$: Store,
    private matDialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private authService: AuthService
  ) {
    this.elementResizeObserver$ = new ResizeObserver(this.resizeObserverCallback);
  }

  ngOnInit(): void {
    this.viewMode$ = this.store$.pipe(select(selectViewMode));
    this.viewMode$.subscribe(viewMode => {
      this._showMobileMenu = viewMode === ViewModeEnum.Mobile;

      if (this._showMobileMenu) {
        this._hideSideMenu = true;
        this._isCollapsed = true;
      } else {
        this._hideSideMenu = false;
        this._isCollapsed = this.getMenuRetractionState();
      }

      this.store$.dispatch(setMenuRetractionModeAction({ isCollapsed: this._isCollapsed }));
    });

    this.getUserInfo();
    this.bindActiveRoute();
  }

  ngAfterViewInit(): void {
    this.elementResizeObserver$.observe(this.menuElementRef.nativeElement);
  }

  ngOnDestroy(): void {
    this.elementResizeObserver$.unobserve(this.menuElementRef.nativeElement);
    this.elementResizeObserver$.disconnect();
  }

  public _getActiveTabPosition(): string {
    const index = this._items.indexOf(this._activeItem);

    const marginSpacing = !this._activeItem.isFirstItem ? `+ (1rem * ${index})` : '';

    return `calc(4.5rem + (3rem * ${index + 1}) ${marginSpacing})`;
  }

  public _handleItemClick(item: MenuItem): void {
    this._activeItem = item;

    this.router.navigateByUrl(item.menuItemValue.routerUrl);
  }

  public _toggleMobileMenu(): void {
    this._isCollapsed = false;
    this._hideSideMenu = false;

    this.store$.dispatch(setMenuRetractionModeAction({ isCollapsed: false }));
  }

  public _toggleCollapseMenu(): void {
    this._isCollapsed = !this._isCollapsed;

    this.setMenuRetractionState(this._isCollapsed);

    this.store$.dispatch(setMenuRetractionModeAction({ isCollapsed: this._isCollapsed }));

    if (this._isCollapsed && this._showMobileMenu) {
      clearTimeout(this.textAnimationDebouncer);

      this.textAnimationDebouncer = setTimeout(() => {
        this._hideSideMenu = true;

        this.cdr.detectChanges();
      }, 300);
    } else {
      this._hideSideMenu = false;
    }
  }

  public _signOut(): void {
    this.store$.dispatch(openDialogAction({ componentType: SignOutDialogComponent }));
  }

  private getUserInfo(): void {
    const jwtHelper = new JwtHelperService();

    const token: DecodedTokenModel = jwtHelper.decodeToken(this.authService.getToken()!) as DecodedTokenModel;

    const { user_personal_name, user_name, authorities } = token;

    this._userPersonalName = user_personal_name;
    this._userName = user_name;

    this.checkUserAuthorities(authorities);
  }

  private checkUserAuthorities(authorities: string[]): void {
    this._items = this._items.filter(item => {
      let canAccess = true;

      item.neededAuthorities?.forEach(neededAuthority => {
        if (!authorities.includes(neededAuthority)) canAccess = false;
      });

      return canAccess;
    });
  }

  private resizeObserverCallback = (event: any) => {
    const entry = event[0] as ResizeObserverEntry;

    const menuWidth = entry.contentRect.width;

    if (menuWidth > 250 && !this._isCollapsed) {
      clearTimeout(this.textAnimationDebouncer);

      this._showExpandedTexts = true;

      this.cdr.detectChanges();

      this.textAnimationDebouncer = setTimeout(() => {
        this._triggerTextAnimation = true;

        this.cdr.detectChanges();
      }, 2);
    } else {
      this._showExpandedTexts = false;
      this._triggerTextAnimation = false;
    }

    clearTimeout(this.menuWidthDebouncer);

    this.menuWidthDebouncer = setTimeout(() => {
      this.store$.dispatch(setMenuWidthAction({ width: menuWidth }));
    }, 100);
  };

  private getMenuRetractionState(): boolean {
    return !!(localStorage.getItem(this.MENU_STATE_VALUE) === 'true');
  }

  private setMenuRetractionState(state: boolean): void {
    localStorage.setItem(this.MENU_STATE_VALUE, JSON.stringify(state));
  }

  private bindActiveRoute(): void {
    const activeRoute = this.router.url.replace('/', '');

    this._activeItem = this._items.find(item => item.menuItemValue.routerUrl === activeRoute)!;
  }
}
