import { MatDialogRef } from '@angular/material/dialog';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DialogManagerState } from '../reducers/dialog-manager.reducer';

export const dialogManagerStateKey = 'dialog-manager';

export const selectDialogRef = createSelector(
  createFeatureSelector(dialogManagerStateKey),
  (state: DialogManagerState): MatDialogRef<any> => state.dialogRef
);
