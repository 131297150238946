import { Directive, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { select, Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';
import { ViewModeEnum } from 'src/app/core/view/view-mode/models/view-mode.enum';
import { selectViewMode } from 'src/app/core/view/view-mode/store/selectors/view-mode.selector';
import { selectMenuRetraction } from '../../menu/store/selectors/menu.selector';

@Directive()
export abstract class RetractableScreen implements OnInit, OnDestroy {
  @HostBinding('class') protected _hostClass: string = '';

  subscription: Subscription = {} as Subscription;

  constructor(protected store$: Store, private title: Title) {}

  ngOnInit(): void {
    this.subscription = combineLatest([
      this.store$.pipe(select(selectMenuRetraction)),
      this.store$.pipe(select(selectViewMode)),
    ]).subscribe(([isCollapsed, viewMode]) => {
      const isMobile = viewMode === ViewModeEnum.Mobile;

      this._hostClass = '_retractable-screen';

      if (isCollapsed) {
        this._hostClass = '_retractable-screen _collapsed-menu';
      } else {
        if (isMobile) {
          // If view mode is mobile, expanded menu is never setted
          this._hostClass = '_retractable-screen _collapsed-menu';
        } else {
          this._hostClass = '_retractable-screen _expanded-menu';
        }
      }

      isMobile ? (this._hostClass += '--mobile ') : (this._hostClass += '--desktop ');
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  protected setPageTitle(pageTitle: string): void {
    this.title.setTitle(pageTitle);
  }
}
