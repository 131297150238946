import { Component, HostListener, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { MOBILE_BREAKPOINT } from './core/view/view-mode/helpers/view.consts';
import { ViewModeEnum } from './core/view/view-mode/models/view-mode.enum';
import { setViewModeAction } from './core/view/view-mode/store/actions/view-mode.action';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private timeoutRef: any;

  @HostListener('window:resize', ['$event']) onResize() {
    let viewMode: ViewModeEnum;

    if (window.innerWidth <= MOBILE_BREAKPOINT) {
      viewMode = ViewModeEnum.Mobile;
    } else {
      viewMode = ViewModeEnum.Desktop;
    }

    clearTimeout(this.timeoutRef);

    this.timeoutRef = setTimeout(() => {
      this.store$.dispatch(setViewModeAction({ mode: viewMode }));
    }, 100);
  }

  constructor(private store$: Store) {}

  ngOnInit(): void {
    this.onResize();
  }
}
