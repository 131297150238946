import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignOutDialogComponent } from './sign-out-dialog/sign-out-dialog.component';
import { SvgIconModule } from '../shared/svg-icon/svg-icon.module';
import { CoreModule } from 'src/app/core/core.module';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [SignOutDialogComponent],
  imports: [CommonModule, SvgIconModule, CoreModule, MatButtonModule, MatDialogModule],
  exports: [SignOutDialogComponent],
})
export class DialogsModule {}
