import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ViewModeEnum } from '../../models/view-mode.enum';
import { ViewModeState } from '../reducers/view-mode.reducer';

export const viewModeStateKey = 'viewMode';

export const selectViewMode = createSelector(
  createFeatureSelector(viewModeStateKey),
  (state: ViewModeState): ViewModeEnum | undefined => state.mode
);
