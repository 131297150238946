import { AbstractControl, ValidationErrors } from '@angular/forms';
import { VuxInputWithValidation } from '../models/vux-input.model';

export class VuxValidators {
  public static inputWithExternalValidation(control: AbstractControl): ValidationErrors | null {
    const inputControl: VuxInputWithValidation = control.value as VuxInputWithValidation;

    if (inputControl.inputValue !== '' && inputControl.isValid && inputControl.isValidated) {
      return null;
    }

    const validationErrors: ValidationErrors = [];

    if (inputControl.inputValue === '') {
      validationErrors['required'] = true;
    }

    if (!inputControl.isValid) {
      validationErrors['isValid'] = true;
    }

    if (inputControl.isValidated === false) {
      validationErrors['isValidated'] = true;
    }

    return validationErrors;
  }
}
