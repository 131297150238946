import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LoginComponent } from './pages/login/login.component';

import { AuthGuard } from './core/auth/guard/auth.guard';
import { LoginGuard } from './core/auth/guard/login.guard';
import { MenuComponent } from './modules/menu/menu.component';
import { CustomerManagementComponent } from './pages/customer-management/customer-management.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard],
  },
  {
    path: '',
    component: MenuComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        pathMatch: 'full',
        component: DashboardComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'clientes',
        pathMatch: 'full',
        component: CustomerManagementComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
