import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VuxInputComponent } from './vux-input.component';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxMaskModule } from 'ngx-mask';
@NgModule({
  declarations: [VuxInputComponent],
  imports: [CommonModule, FormsModule, MatIconModule, MatButtonModule, MatTooltipModule, NgxMaskModule.forRoot()],
  exports: [VuxInputComponent],
})
export class VuxInputModule {}
