import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerManagementComponent } from './customer-management.component';
import { CustomerModule } from 'src/app/modules/customer/customer.module';

@NgModule({
  declarations: [CustomerManagementComponent],
  imports: [CommonModule, CustomerModule],
})
export class CustomerManagementModule {}
