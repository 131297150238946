import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './menu.component';
import { SvgIconModule } from '../shared/svg-icon/svg-icon.module';

import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { CacheInterceptor } from '../shared/cache/cache.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { initialMenuState, menuReducer } from './store/reducers/menu.reducer';
import { AuthService } from 'src/app/core/auth/service/auth.service';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [MenuComponent],
  imports: [
    CommonModule,
    SvgIconModule,
    MatDialogModule,
    MatTooltipModule,
    MatIconModule,
    RouterModule,
    StoreModule.forFeature('menu', menuReducer, {
      initialState: initialMenuState,
    }),
  ],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true,
    },
  ],
})
export class MenuModule {}
