import { createReducer, on } from '@ngrx/store';
import { DataPageModel } from 'src/app/modules/shared/data-table/models/data-table.model';
import { CustomerModel } from '../../models/customer.model';
import {
  createCustomerAction,
  createCustomerFailureAction,
  createCustomerSuccessAction,
  deleteCustomerAction,
  deleteCustomerFailureAction,
  deleteCustomerSuccessAction,
  requestCustomersAction,
  requestCustomersFailureAction,
  requestCustomersSuccessAction,
  updateCustomerAction,
  updateCustomerFailureAction,
  updateCustomerSuccessAction,
} from '../actions/customer.action';

export interface CustomerState {
  showListLoader: boolean;
  showSubmitLoader: boolean;
  customersContent: DataPageModel<CustomerModel>;
}

export const initialCustomerState: CustomerState = {
  showListLoader: false,
  showSubmitLoader: false,
  customersContent: {} as DataPageModel<CustomerModel>,
};

export const customerReducer = createReducer(
  initialCustomerState,
  on(createCustomerAction, (state, action): CustomerState => {
    return { ...state, showSubmitLoader: true };
  }),
  on(createCustomerSuccessAction, (state, action): CustomerState => {
    return { ...state, showSubmitLoader: false };
  }),
  on(createCustomerFailureAction, (state, action): CustomerState => {
    return { ...state, showSubmitLoader: false };
  }),
  on(updateCustomerAction, (state, action): CustomerState => {
    return { ...state, showSubmitLoader: true };
  }),
  on(updateCustomerSuccessAction, (state, action): CustomerState => {
    return { ...state, showSubmitLoader: false };
  }),
  on(updateCustomerFailureAction, (state, action): CustomerState => {
    return { ...state, showSubmitLoader: false };
  }),
  on(deleteCustomerAction, (state, action): CustomerState => {
    return { ...state, showSubmitLoader: true };
  }),
  on(deleteCustomerSuccessAction, (state, action): CustomerState => {
    return { ...state, showSubmitLoader: false };
  }),
  on(deleteCustomerFailureAction, (state, action): CustomerState => {
    return { ...state, showSubmitLoader: false };
  }),
  on(requestCustomersAction, (state, action): CustomerState => {
    return { ...state, showListLoader: true };
  }),
  on(requestCustomersSuccessAction, (state, action): CustomerState => {
    action.payload.content.map(customer => {
      customer.inicioContratoFormatado = new Intl.DateTimeFormat('pt-BR').format(new Date(customer.inicioContrato));
      customer.fimContratoFormatado = new Intl.DateTimeFormat('pt-BR').format(new Date(customer.fimContrato));
      customer.cnpj = customer.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    });

    return { ...state, customersContent: action.payload, showListLoader: false };
  }),
  on(requestCustomersFailureAction, (state, action): CustomerState => {
    return { ...state, showListLoader: false };
  })
);
