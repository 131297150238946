import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { selectDialogRef } from 'src/app/modules/shared/dialog-manager/store/selectors/dialog-manager.selector';
import { errorSnackBar, successSnackBar } from 'src/app/modules/shared/snack-bar/helpers/snack-bar.helper';
import { CustomerService } from '../../services/customer.service';
import {
  createCustomerAction,
  createCustomerFailureAction,
  createCustomerSuccessAction,
  deleteCustomerAction,
  deleteCustomerFailureAction,
  deleteCustomerSuccessAction,
  requestCustomersAction,
  requestCustomersFailureAction,
  requestCustomersSuccessAction,
  updateCustomerAction,
  updateCustomerFailureAction,
  updateCustomerSuccessAction,
} from '../actions/customer.action';
import { selectCustomersContent } from '../selectors/customer.selector';

@Injectable()
export class CustomerEffects {
  constructor(
    private store$: Store,
    private actions$: Actions,
    private customerService: CustomerService,
    private snackBar: MatSnackBar
  ) {}

  requestCustomersEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(requestCustomersAction),
      switchMap(action =>
        this.customerService.findAll(action.pageable).pipe(
          map(payload => requestCustomersSuccessAction({ payload })),
          catchError(error =>
            of(requestCustomersFailureAction()).pipe(
              tap(() => {
                this.snackBar.open(
                  'Um erro inesperado ocorreu ao buscar os clientes cadastrados. Por favor, tente novamente mais tarde',
                  'OK',
                  errorSnackBar
                );
              })
            )
          )
        )
      )
    );
  });

  createCustomerEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(createCustomerAction),
      concatLatestFrom(() => this.store$.pipe(select(selectDialogRef))),
      switchMap(([action, dialogRef]) =>
        this.customerService.store(action.customerInput).pipe(
          map(payload => createCustomerSuccessAction({ customer: payload })),
          tap(payload => {
            this.snackBar.open(
              `Cliente [${payload.customer.nomeFantasia}] cadastrado com sucesso!`,
              'OK',
              successSnackBar
            );

            dialogRef.close();
          }),
          catchError(error =>
            of(createCustomerFailureAction()).pipe(
              tap(() => {
                this.snackBar.open(
                  'Um erro inesperado ocorreu ao cadastrar o cliente. Por favor, tente novamente mais tarde',
                  'OK',
                  errorSnackBar
                );
              })
            )
          )
        )
      )
    );
  });

  createCustomerSuccessEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(createCustomerSuccessAction),
      concatLatestFrom(() => this.store$.pipe(select(selectCustomersContent))),
      map(([, customersContent]) =>
        requestCustomersAction({ pageable: { pageNumber: 0, pageSize: customersContent.pageSize } })
      )
    );
  });

  updateCustomerEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateCustomerAction),
      concatLatestFrom(() => this.store$.pipe(select(selectDialogRef))),
      switchMap(([action, dialogRef]) => {
        return this.customerService.update(action.id, action.customerInput).pipe(
          map(payload => updateCustomerSuccessAction({ customer: payload })),
          tap(payload => {
            this.snackBar.open(
              `Cliente [${payload.customer.nomeFantasia}] atualizado com sucesso!`,
              'OK',
              successSnackBar
            );

            dialogRef.close();
          }),
          catchError(error =>
            of(updateCustomerFailureAction()).pipe(
              tap(() => {
                this.snackBar.open(
                  'Um erro inesperado ocorreu ao atualizar o cliente. Por favor, tente novamente mais tarde',
                  'OK',
                  errorSnackBar
                );
              })
            )
          )
        );
      })
    );
  });

  updateCustomerSuccessEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateCustomerSuccessAction),
      concatLatestFrom(() => this.store$.pipe(select(selectCustomersContent))),
      map(([, customersContent]) =>
        requestCustomersAction({ pageable: { pageNumber: 0, pageSize: customersContent.pageSize } })
      )
    );
  });

  deleteCustomerEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteCustomerAction),
      concatLatestFrom(() => this.store$.pipe(select(selectDialogRef))),
      switchMap(([action, dialogRef]) => {
        return this.customerService.delete(action.customer.id).pipe(
          map(() => deleteCustomerSuccessAction({ customer: action.customer })),
          tap(() => {
            this.snackBar.open(
              `Cliente [${action.customer.nomeFantasia}] removido com sucesso!`,
              'OK',
              successSnackBar
            );

            dialogRef.close();
          })
        );
      }),
      catchError(error =>
        of(deleteCustomerFailureAction()).pipe(
          tap(() => {
            this.snackBar.open(
              'Um erro inesperado ocorreu ao remover o cliente. Por favor, tente novamente mais tarde',
              'OK',
              errorSnackBar
            );
          })
        )
      )
    );
  });

  deleteCustomerSuccessEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteCustomerSuccessAction),
      concatLatestFrom(() => this.store$.pipe(select(selectCustomersContent))),
      map(([, customersContent]) =>
        requestCustomersAction({ pageable: { pageNumber: 0, pageSize: customersContent.pageSize } })
      )
    );
  });
}
