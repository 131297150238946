import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { map, tap } from 'rxjs';
import { ViewModeEnum } from 'src/app/core/view/view-mode/models/view-mode.enum';
import { selectViewMode } from 'src/app/core/view/view-mode/store/selectors/view-mode.selector';
import { openDialogAction, openDialogSuccessAction } from '../actions/dialog.action';

@Injectable()
export class DialogEffects {
  constructor(private actions$: Actions, private store$: Store, private dialog: MatDialog) {}

  openDialogEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(openDialogAction),
      concatLatestFrom(() => this.store$.pipe(select(selectViewMode))),
      map(([action, viewMode]) => {
        const isDesktop = viewMode === ViewModeEnum.Desktop;

        const dialogRef = this.dialog.open(action.componentType, {
          width: isDesktop ? '40%' : '85%',
          data: action.data,
          autoFocus: false,
        });

        return openDialogSuccessAction({ dialogRef });
      })
    );
  });
}
