<div class="dialog">
  <div class="dialog-title">
    <h1>[{{ _guia.id }}] [{{ _guia.nomeGuia }}] Detalhamento da Geração</h1>
  </div>
  <div class="dialog-body" mat-dialog-content>
    <div class="details">
      <mat-form-field appearance="fill">
        <mat-label>ID</mat-label>
        <input [value]="_guia.id" matInput disabled />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Nome da Guia</mat-label>
        <input [value]="_guia.nomeGuia" matInput disabled />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Código da Receita</mat-label>
        <input [value]="_guia.codReceita" matInput disabled />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Data de Vencimento</mat-label>
        <input [value]="_guia.dataVenct" matInput disabled />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>UF</mat-label>
        <input [value]="_guia.uf" matInput disabled />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Valor</mat-label>
        <input [value]="_guia.valorFormatado" matInput disabled />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Juros</mat-label>
        <input [value]="_guia.jurosFormatado" matInput disabled />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Multa</mat-label>
        <input [value]="_guia.multaFormatado" matInput disabled />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Valor Total</mat-label>
        <input [value]="_guia.valorTotalFormatado" matInput disabled />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Referência</mat-label>
        <input [value]="_guia.referencia" matInput disabled />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Nome</mat-label>
        <input [value]="_guia.nome" matInput disabled />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>CNPJ</mat-label>
        <input [value]="_guia.cnpj" matInput disabled />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>DDD</mat-label>
        <input [value]="_guia.ddd" matInput disabled />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Telefone</mat-label>
        <input [value]="_guia.telefone" matInput disabled />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Município</mat-label>
        <input [value]="_guia.municipio" matInput disabled />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Endereço</mat-label>
        <input [value]="_guia.endereco" matInput disabled />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>CEP</mat-label>
        <input [value]="_guia.cep" matInput disabled />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>I.E.</mat-label>
        <input [value]="_guia.ie" matInput disabled />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>CNAE</mat-label>
        <input [value]="_guia.cnae" matInput disabled />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Número do Banco</mat-label>
        <input [value]="_guia.nroBanco" matInput disabled />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Convênio</mat-label>
        <input [value]="_guia.convenio" matInput disabled />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Identificado Único</mat-label>
        <input [value]="_guia.identificador" matInput disabled />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Identificador do Processo</mat-label>
        <input [value]="_guia.jobHash" matInput disabled />
      </mat-form-field>
    </div>
  </div>
  <div class="dialog-footer">
    <button mat-button matDialogClose>Fechar</button>
  </div>
</div>
