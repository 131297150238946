import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { DataPageModel, DataPagingModel, ToolbarConfig } from '../../shared/data-table/models/data-table.model';
import { openDialogAction } from '../../shared/dialog-manager/store/actions/dialog.action';
import { CustomerDialogComponent } from '../customer-dialog/customer-dialog.component';
import { DeleteCustomerDialogComponent } from '../delete-customer-dialog/delete-customer-dialog.component';
import { CustomerModel } from '../models/customer.model';
import { requestCustomersAction } from '../store/actions/customer.action';
import { selectCustomersContent, selectCustomersListLoaderState } from '../store/selectors/customer.selector';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerListComponent implements OnInit {
  _displayedColumns: string[] = [
    'id',
    'clientId',
    'cnpj',
    'razaoSocial',
    'nomeFantasia',
    'inicioContrato',
    'fimContrato',
    'delete',
  ];

  _showLoader = false;

  _toolbarConfig: ToolbarConfig = {
    buttonDisplay: {
      add: true,
      refresh: true,
    },
  };

  _pageOptions: DataPagingModel = {
    pageNumber: 0,
    pageSize: 5,
    totalItems: 0,
  };

  _pagedContent: DataPageModel<CustomerModel> = {} as DataPageModel<CustomerModel>;

  _isLoading$: Observable<boolean> = {} as Observable<boolean>;

  private customersContent$: Observable<DataPageModel<CustomerModel>> = {} as Observable<DataPageModel<CustomerModel>>;

  private timeoutRef: any;

  constructor(private store$: Store, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this._isLoading$ = this.store$.pipe(select(selectCustomersListLoaderState));

    this.customersContent$ = this.store$.pipe(select(selectCustomersContent));
    this.customersContent$.subscribe(customersContent => {
      this._pagedContent = customersContent;

      this._pageOptions.totalItems = customersContent.totalElements;

      this.cdr.markForCheck();
    });

    this._fetchContent();
  }

  _fetchContent(): void {
    this.store$.dispatch(requestCustomersAction({ pageable: this._pageOptions }));
  }

  _handleCustomerClick(customer: CustomerModel): void {
    this.timeoutRef = setTimeout(() => {
      this.store$.dispatch(openDialogAction({ componentType: CustomerDialogComponent, data: customer }));
    }, 0.1);
  }

  _handleDeleteCustomerClick(customer: CustomerModel): void {
    setTimeout(() => {
      clearTimeout(this.timeoutRef);

      this.store$.dispatch(openDialogAction({ componentType: DeleteCustomerDialogComponent, data: customer }));
    }, 0.1);
  }

  _handlePaginationChange(event: PageEvent): void {
    if (event.pageSize !== this._pageOptions.pageSize) {
      this._pageOptions.pageNumber = 0;
      this._pageOptions.pageSize = event.pageSize;
    } else {
      this._pageOptions.pageNumber = event.pageIndex;
    }

    this._fetchContent();
  }

  _handleAddCustomerClick(): void {
    this.store$.dispatch(openDialogAction({ componentType: CustomerDialogComponent }));
  }
}
