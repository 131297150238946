import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { MatTableModule } from '@angular/material/table';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { GuiaListComponent } from './guia-list/guia-list.component';
import { GuiaModalComponent } from './guia-modal/guia-modal.component';
import { CustomMatPaginator } from '../shared/data-table/helpers/custom-paginator';
import { GuiaService } from './services/guia.service';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [GuiaListComponent, GuiaModalComponent],
  imports: [
    CommonModule,
    SharedModule,
    HttpClientModule,
    MatTableModule,
    MatPaginatorModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
  ],
  exports: [GuiaListComponent, GuiaModalComponent],
  providers: [
    GuiaService,
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginator,
    },
  ],
})
export class GuiaModule {}
