import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { lastValueFrom, Observable } from 'rxjs';
import { RetractableComponent } from '../../shared/retractable-component/retractable-component';
import { VuxValidators } from '../../vux/vux-input/helpers/vux-input.validator';
import { VuxInputConfig, VuxInputMaskConfig } from '../../vux/vux-input/models/vux-input.model';
import {
  clientIdHelpMessage,
  clientIdValidationErrorMessage,
  clientIdValidationSuccessMessage,
} from '../helpers/customer.consts';
import { CustomerInputModel, CustomerModel } from '../models/customer.model';
import { CustomerService } from '../services/customer.service';
import { createCustomerAction, updateCustomerAction } from '../store/actions/customer.action';
import { selectCustomerSubmitLoaderState } from '../store/selectors/customer.selector';

@Component({
  selector: 'app-customer-dialog',
  templateUrl: './customer-dialog.component.html',
  styleUrls: ['./customer-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomerDialogComponent extends RetractableComponent implements OnInit {
  _form = this.fb.group({
    cnpj: this.fb.control('', [Validators.required, Validators.minLength(14)]),
    clientIdWrapper: this.fb.control({ inputValue: '', isValid: false, isValidated: false }, [
      VuxValidators.inputWithExternalValidation,
    ]),
    razaoSocial: this.fb.control('', [Validators.required]),
    nomeFantasia: this.fb.control('', [Validators.required]),
    inicioContrato: this.fb.control<Date | null>(null, [Validators.required]),
    fimContrato: this.fb.control<Date | null>(null, [Validators.required]),
  });

  _clientIdInputConfig: VuxInputConfig = {
    validation: {
      validate: true,
      validationFn: async (clientId: string) => {
        const { isClientIdAvailable } = await lastValueFrom(this.customerService.validateClientId(clientId));

        return isClientIdAvailable;
      },
      showValidationSuccessMessage: true,
      validationErrorMessage: clientIdValidationErrorMessage,
      validationSuccessMessage: clientIdValidationSuccessMessage,
      hasErrors: this._fieldHasErrors(this._clientId),
    },
    helpInfo: {
      showHelp: true,
      helpMessage: clientIdHelpMessage,
    },
  };

  _isLoading$: Observable<boolean> = {} as Observable<boolean>;

  _cnpjMaskConfig: VuxInputMaskConfig = {
    showMaskTyped: true,
    maskSpecialCharacters: ['.', '/', '-'],
  };

  _clientIdMaskConfig: VuxInputMaskConfig = {
    maskPatterns: {
      L: {
        pattern: new RegExp('([a-z]|[-]|[_]|[0-9])'),
      },
    },
    maskSpecialCharacters: ['-'],
    dropSpecialCharacters: false,
  };

  constructor(
    store$: Store,
    private fb: FormBuilder,
    private customerService: CustomerService,
    @Inject(MAT_DIALOG_DATA) public _customer: CustomerModel
  ) {
    super(store$);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this._isLoading$ = this.store$.pipe(select(selectCustomerSubmitLoaderState));

    if (this._customer) {
      const [inicioContrato, fimContrato] = this._transformDates(
        this._customer.inicioContrato as string,
        this._customer.fimContrato as string
      );

      this._form.patchValue({
        cnpj: this._customer.cnpj,
        clientIdWrapper: { inputValue: this._customer.clientId, isValid: true, isValidated: true },
        razaoSocial: this._customer.razaoSocial,
        nomeFantasia: this._customer.nomeFantasia,
        inicioContrato: inicioContrato,
        fimContrato: fimContrato,
      });
    }
  }

  _handleSubmit(): void {
    // TODO: Refactor this implementation to build request body
    const clientId = this._form.value.clientIdWrapper!.inputValue;
    const { cnpj, razaoSocial, nomeFantasia, inicioContrato, fimContrato } = this._form.value;

    const customer: CustomerInputModel = {
      cnpj: cnpj!,
      razaoSocial: razaoSocial!,
      clientId,
      nomeFantasia: nomeFantasia!,
      inicioContrato: inicioContrato!,
      fimContrato: fimContrato!,
    };

    if (this._customer) {
      if (this.isPayloadEqual(customer, this._customer)) return;

      this.store$.dispatch(updateCustomerAction({ id: this._customer.id, customerInput: customer }));
    } else {
      this.store$.dispatch(createCustomerAction({ customerInput: customer }));
    }
  }

  _fieldHasErrors(field: AbstractControl, ignoredErrors?: string[]): boolean {
    return !field.touched && !field.dirty ? false : field.errors !== null;
  }

  _transformDates(...dates: string[]): Date[] {
    const transformedDates: Date[] = [];

    dates.forEach(date => {
      transformedDates.push(new Date(date));
    });

    return transformedDates;
  }

  private isPayloadEqual(customer: CustomerInputModel, createdCustomer: CustomerModel): boolean {
    const [inicioContrato, fimContrato] = this._transformDates(
      createdCustomer.inicioContrato as string,
      createdCustomer.fimContrato as string
    );

    const aux: CustomerInputModel = {
      cnpj: createdCustomer.cnpj,
      razaoSocial: createdCustomer.razaoSocial,
      clientId: createdCustomer.clientId,
      nomeFantasia: createdCustomer.nomeFantasia,
      inicioContrato: inicioContrato,
      fimContrato: fimContrato,
    };

    return JSON.stringify(customer) === JSON.stringify(aux);
  }

  get _cnpj() {
    return this._form.get('cnpj')!;
  }

  get _razaoSocial() {
    return this._form.get('razaoSocial')!;
  }

  get _clientId() {
    return this._form.get('clientIdWrapper')!;
  }

  get _nomeFantasia() {
    return this._form.get('nomeFantasia')!;
  }

  get _inicioContrato() {
    return this._form.get('inicioContrato')!;
  }

  get _fimContrato() {
    return this._form.get('fimContrato')!;
  }
}
